import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/car';
const model = 'cars/car';
const modelExposedName = 'Car';
const wizardAction = 'create';

const Create = (params) => {

    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);

    // DATA PROVIDER
    const { doAction, item, loadOne, loadAll, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);

    const modelName = existingModules.modelName
    const detailConfig = {
            "tabs": [
                {
                    "name": "create_new_car",
                    "fieldsets": [
                        {
                            "name": "car_information",
                            "fields": {
                                "status": {
                                    "type": "select",
                                    "required": true,
                                    "options": [
                                        {
                                            "label": "in_configuration",
                                            "value": "in_configuration"
                                        },
                                        {
                                            "label": "in_order",
                                            "value": "in_order"
                                        },
                                        {
                                            "label": "running",
                                            "value": "running"
                                        },
                                        {
                                            "label": "closed",
                                            "value": "closed"
                                        }
                                    ],
                                    "className": "col-4",
                                    "placeholder": "select",
                                    "icon": "faChartNetwork"
                                },
                                "usage": {
                                    "type": "select",
                                    "options": [
                                        {
                                            "label": "mixedUse",
                                            "value": "mixedUse"
                                        },
                                        {
                                            "label": "serviceUse",
                                            "value": "serviceUse"
                                        },
                                        {
                                            "label": "specialVehicles",
                                            "value": "specialVehicles"
                                        }
                                    ],
                                    "required": true,
                                    "className": "col-4",
                                    "placeholder": "select",
                                    "icon": "faCarBus"
                                },
                                "isPool": {
                                    "type": "select",
                                    "options": [
                                        {
                                            "label": "true",
                                            "value": "true"
                                        },
                                        {
                                            "label": "false",
                                            "value": "false"
                                        }
                                    ],
                                    "required": true,
                                    "className": "col-4",
                                    "placeholder": "select",
                                    "icon": "faPeople"
                                },
                                "plate": {
                                    "type": "text",
                                    "className": "col-12",
                                    "placeholder": "insert",
                                    "icon": "faFingerprint",
                                    "visibilitycondition": [
                                        [
                                            "status",
                                            "in",
                                            [
                                                "running",
                                                "preleasing",
                                                "substitute",
                                                "closed",
                                            ]
                                        ]
                                    ]
                                },

                                "contract_status": {
                                    "type": "select",
                                    "required": true,
                                    "options": [
                                        {
                                            "label": "existing",
                                            "value": "existing"
                                        },
                                        {
                                            "label": "new",
                                            "value": "new"
                                        },
                                    ],
                                    "className": "col-12",
                                    "placeholder": "select",
                                    "icon": "faPen"
                                },
                                "contract": {
                                    "type": "relationshipSingle",
                                    "relation": {
                                        "drawerRoute": "contracts",
                                        "limit": 1
                                    },
                                    "view": "contract",
                                    "visibilitycondition": [
                                        [
                                            "contract_status",
                                            "=",
                                            "existing"
                                        ]
                                    ]
                                },


                                "contract.contractIdentifier": {
                                    "type": "text",
                                    "className": "col-12",
                                    "icon": "faCar",
                                    "label": "contractIdentifier",
                                    "visibilitycondition": [
                                        [
                                            "contract_status",
                                            "=",
                                            "new"
                                        ]
                                    ]
                                },
                                "contract.configurationIdentifier": {
                                    "type": "text",
                                    "className": "col-4",
                                    "label": "configurationIdentifier",
                                    "visibilitycondition": [
                                        [
                                            "contract_status",
                                            "=",
                                            "new"
                                        ],
                                        "and",
                                        [
                                            "status",
                                            "in",
                                            [
                                                "in_configuration",
                                            ]
                                        ]
                                    ]
                                },
                                "contract.configured_at": {
                                    "type": "date",
                                    "className": "col-4",
                                    "label": "configured_at",
                                    "visibilitycondition": [
                                        [
                                            "contract_status",
                                            "=",
                                            "new"
                                        ],
                                        "and",
                                        [
                                            "status",
                                            "in",
                                            [
                                                "in_configuration",
                                            ]
                                        ]
                                    ]
                                },
                                "contract.configurationSource": {
                                    "type": "select",
                                    "options": [
                                        {
                                            "label": "configurator",
                                            "value": "configurator"
                                        },
                                        {
                                            "label": "external",
                                            "value": "external"
                                        },
                                        {
                                            "label": "customconfigurator",
                                            "value": "customconfigurator"
                                        }
                                    ],
                                    "label": "configurationSource",
                                    "className": "col-4",
                                    "visibilitycondition": [
                                        [
                                            "contract_status",
                                            "=",
                                            "new"
                                        ],
                                        "and",
                                        [
                                            "status",
                                            "in",
                                            [
                                                "in_configuration",
                                            ]
                                        ]
                                    ]
                                },
                                "contract.ordered_at": {
                                    "type": "date",
                                    "className": "col-4",
                                    "icon": "faCalendarPen",
                                    "label": "ordered_at",
                                    "visibilitycondition": [
                                        [
                                            "contract_status",
                                            "=",
                                            "new"
                                        ],
                                        "and",
                                        [
                                            "status",
                                            "in",
                                            [
                                                "running",
                                                "preleasing",
                                                "substitute",
                                                "closed",
                                                "in_order",
                                            ]
                                        ]
                                    ]
                                },
                                "contract.orderIdentifier": {
                                    "type": "text",
                                    "className": "col-4",
                                    "label": "orderIdentifier",
                                    "visibilitycondition": [
                                        [
                                            "contract_status",
                                            "=",
                                            "new"
                                        ],
                                        "and",
                                        [
                                            "status",
                                            "in",
                                            [
                                                "running",
                                                "preleasing",
                                                "substitute",
                                                "closed",
                                                "in_order",
                                            ]
                                        ]
                                    ]
                                },
                                "contract.purchaseOrderIdentifier": {
                                    "type": "text",
                                    "className": "col-4",
                                    "label": "purchaseOrderIdentifier",
                                    "visibilitycondition": [
                                        [
                                            "contract_status",
                                            "=",
                                            "new"
                                        ],
                                        "and",
                                        [
                                            "status",
                                            "in",
                                            [
                                                "running",
                                                "preleasing",
                                                "substitute",
                                                "closed",
                                                "in_order",
                                            ]
                                        ]
                                    ]
                                },
                                "contract.renewedCarPlate": {
                                    "type": "text",
                                    "className": "col-6",
                                    "icon": "faCar",
                                    "label": "renewedCarPlate",
                                    "visibilitycondition": [
                                        [
                                            "contract_status",
                                            "=",
                                            "new"
                                        ],
                                        "and",
                                        [
                                            "status",
                                            "in",
                                            [
                                                "substitute",
                                            ]
                                        ]
                                    ]
                                },
                                "contract.renewedCarContractEnd_at": {
                                    "type": "date",
                                    "className": "col-6",
                                    "icon": "faCalendarXmark",
                                    "label": "renewedCarContractEnd_at",
                                    "visibilitycondition": [
                                        [
                                            "contract_status",
                                            "=",
                                            "new"
                                        ],
                                        "and",
                                        [
                                            "status",
                                            "in",
                                            [
                                                "substitute",
                                            ]
                                        ]
                                    ]
                                },
                                "contract.renter": {
                                    "type": "relationshipSingle",
                                    "required": true,
                                    "relation": {
                                        "drawerRoute": "legalentities",
                                        "filter": {
                                            "items": [
                                                {
                                                    "field": "supplier_type",
                                                    "operator": "equals",
                                                    "value": "renter"
                                                }
                                            ]
                                        }
                                    },
                                    "view": "legalEntity",
                                    "label": "renter",
                                    "visibilitycondition": [
                                        [
                                            "contract_status",
                                            "=",
                                            "new"
                                        ]
                                    ]
                                },
                                "legalEntity": {
                                    "type": "relationshipSingle",
                                    "required": true,
                                    "relation": {
                                        "drawerRoute": "legalentities",
                                        "filter": {
                                            "items": [
                                                {
                                                    "field": "type",
                                                    "operator": "equals",
                                                    "value": "customer"
                                                }
                                            ]
                                        }
                                    },
                                    "view": "legalEntity",
                                    "label": "customer"
                                }
                            }
                        },
                        {
                            "name": "setup",
                            "fields": {

                                "setup.source": {
                                    "type": "select",
                                    "options": [
                                        {
                                            "label": "manual",
                                            "value": "manual"
                                        },
                                        {
                                            "label": "infocar",
                                            "value": "infocar"
                                        },
                                        {
                                            "label": "other",
                                            "value": "other"
                                        }
                                    ],
                                    "required": true,
                                    "className": "col-12",
                                    "placeholder": "insert"
                                },
                                "setup": {
                                    "type": "makeModelSetup",
                                    "required": true,
                                    "className": "col-4",
                                    "placeholder": "insert",
                                    "prefix": "setup."
                                },

                                "setup.startImmatriculation_at": {
                                    "type": "date",
                                    "className": "col-4",
                                    "placeholder": "insert",
                                    "icon": "faCalendarStar"
                                },
                                "setup.endImmatriculation_at": {
                                    "type": "date",
                                    "className": "col-4",
                                    "placeholder": "insert",
                                    "icon": "faCalendarXmark"
                                },
                                "setup.modelYear": {
                                    "type": "text",
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "setup.externalID": {
                                    "type": "text",
                                    "className": "col-12",
                                    "placeholder": "insert",
                                    "visibilitycondition": [
                                        [
                                            "setup.source",
                                            "in",
                                            [
                                                "infocar",
                                                "other"
                                            ]
                                        ]
                                    ]
                                },
                                "technical_information": {
                                    "type": "sectiontitle",
                                    "className": "col-12"
                                },
                                "setup.bodyType": {
                                    "type": "select",
                                    "options": [
                                        {
                                            "label": "hatchback",
                                            "value": "hatchback"
                                        },
                                        {
                                            "label": "sedan",
                                            "value": "sedan"
                                        },
                                        {
                                            "label": "suv",
                                            "value": "suv"
                                        },
                                        {
                                            "label": "station-wagon",
                                            "value": "station-wagon"
                                        },
                                        {
                                            "label": "off-road",
                                            "value": "off-road"
                                        },
                                        {
                                            "label": "city-car",
                                            "value": "city-car"
                                        },
                                        {
                                            "label": "micro-car",
                                            "value": "micro-car"
                                        },
                                        {
                                            "label": "cabrio",
                                            "value": "cabrio"
                                        },
                                        {
                                            "label": "mpv",
                                            "value": "mpv"
                                        },
                                        {
                                            "label": "van",
                                            "value": "van"
                                        },
                                        {
                                            "label": "minivan",
                                            "value": "minivan"
                                        },
                                        {
                                            "label": "coupe",
                                            "value": "coupe"
                                        },
                                        {
                                            "label": "pickup",
                                            "value": "pickup"
                                        },
                                        {
                                            "label": "unknown",
                                            "value": "unknown"
                                        }
                                    ],
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "setup.drivetrain": {
                                    "type": "select",
                                    "options": [
                                        {
                                            "label": "front-wheel-drive",
                                            "value": "front-wheel-drive"
                                        },
                                        {
                                            "label": "rear-wheel-drive",
                                            "value": "rear-wheel-drive"
                                        },
                                        {
                                            "label": "all-wheel-drive",
                                            "value": "all-wheel-drive"
                                        },
                                        {
                                            "label": "four-wheel-drive",
                                            "value": "four-wheel-drive"
                                        },
                                        {
                                            "label": "selectable-four-wheel-drive",
                                            "value": "selectable-four-wheel-drive"
                                        },
                                        {
                                            "label": "unknown",
                                            "value": "unknown"
                                        }
                                    ],
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "setup.gearbox": {
                                    "type": "select",
                                    "options": [
                                        {
                                            "label": "manual",
                                            "value": "manual"
                                        },
                                        {
                                            "label": "automatic",
                                            "value": "automatic"
                                        },
                                        {
                                            "label": "cvt",
                                            "value": "cvt"
                                        },
                                        {
                                            "label": "semi-automatic",
                                            "value": "semi-automatic"
                                        },
                                        {
                                            "label": "direct-drive",
                                            "value": "direct-drive"
                                        },
                                        {
                                            "label": "unknown",
                                            "value": "unknown"
                                        }
                                    ],
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "setup.engineDisplacement": {
                                    "type": "text",
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "setup.fuelType": {
                                    "type": "select",
                                    "options": [
                                        {
                                            "label": "petrol",
                                            "value": "petrol"
                                        },
                                        {
                                            "label": "diesel",
                                            "value": "diesel"
                                        },
                                        {
                                            "label": "hev",
                                            "value": "hev"
                                        },
                                        {
                                            "label": "phev",
                                            "value": "phev"
                                        },
                                        {
                                            "label": "mhev",
                                            "value": "mhev"
                                        },
                                        {
                                            "label": "fhev",
                                            "value": "fhev"
                                        },
                                        {
                                            "label": "erev",
                                            "value": "erev"
                                        },
                                        {
                                            "label": "bev",
                                            "value": "bev"
                                        },
                                        {
                                            "label": "petrol-lpg",
                                            "value": "petrol-lpg"
                                        },
                                        {
                                            "label": "lpg",
                                            "value": "lpg"
                                        },
                                        {
                                            "label": "cng",
                                            "value": "cng"
                                        },
                                        {
                                            "label": "unknown",
                                            "value": "unknown"
                                        }
                                    ],
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "setup.fuelTankCapacity": {
                                    "type": "text",
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "setup.seats": {
                                    "type": "text",
                                    "className": "col-6",
                                    "placeholder": "insert"
                                },
                                "setup.doors": {
                                    "type": "text",
                                    "className": "col-6",
                                    "placeholder": "insert"
                                },
                                "environmental_information": {
                                    "type": "sectiontitle",
                                    "className": "col-12"
                                },
                                "setup.environmentalClass": {
                                    "type": "select",
                                    "options": [
                                        {
                                            "label": "Euro1",
                                            "value": "Euro1"
                                        },
                                        {
                                            "label": "Euro2",
                                            "value": "Euro2"
                                        },
                                        {
                                            "label": "Euro3",
                                            "value": "Euro3"
                                        },
                                        {
                                            "label": "Euro4",
                                            "value": "Euro4"
                                        },
                                        {
                                            "label": "Euro5",
                                            "value": "Euro5"
                                        },
                                        {
                                            "label": "Euro6",
                                            "value": "Euro6"
                                        },
                                        {
                                            "label": "Euro6d",
                                            "value": "Euro6d"
                                        },
                                        {
                                            "label": "ZEV",
                                            "value": "ZEV"
                                        },
                                        {
                                            "label": "unknown",
                                            "value": "unknown"
                                        }
                                    ],
                                    "className": "col-3",
                                    "placeholder": "insert"
                                },
                                "setup.emissions": {
                                    "type": "text",
                                    "className": "col-3",
                                    "placeholder": "insert"
                                },
                                "setup.powerHp": {
                                    "type": "text",
                                    "className": "col-3",
                                    "placeholder": "insert"
                                },
                                "setup.powerKw": {
                                    "type": "text",
                                    "className": "col-3",
                                    "placeholder": "insert"
                                },
                                "setup.fuelCombinedConsumption": {
                                    "type": "text",
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "setup.fuelUrbanConsumption": {
                                    "type": "text",
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "setup.fuelExtraUrbanConsumption": {
                                    "type": "text",
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "electrification_information": {
                                    "type": "sectiontitle",
                                    "className": "col-12"
                                },
                                "setup.electricBatteryCapacity": {
                                    "type": "text",
                                    "className": "col-6",
                                    "placeholder": "insert"
                                },
                                "setup.electricRangeKm": {
                                    "type": "text",
                                    "className": "col-6",
                                    "placeholder": "insert"
                                },
                                "setup.electricCombinedConsumption": {
                                    "type": "text",
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "setup.electricUrbanConsumption": {
                                    "type": "text",
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "setup.electricExtraUrbanConsumption": {
                                    "type": "text",
                                    "className": "col-4",
                                    "placeholder": "insert"
                                }
                            }
                        }
                    ]
                }
            ],
            "validation": []
        }
    useEffect(() => {
        loadOne(params.filters?._id);
    }, []);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])


    const handleSave = async (data) => {
        doAction && doAction({ action: wizardAction, model: model ? model : dataProviderName, data: data });
    }

    const values = {
        id:item?.data._id,
        status:'in_configuration'
    }

    return <>
        <DetailComponent
            values={values}
            modelName={modelName}
            detailConfig={detailConfig}
            onEditing={setEditing}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t('New {{itemname}}', {itemname:t(modelExposedName)})}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.create']}
            header={
                <InfoHeader
                    view="wizard"
                    title={t('Create new {{itemname}}', {itemname:t(modelExposedName)})}
                    subtitle={t('Fill the basic information to create a new {{itemname}} that you will be able to edit via the standard interface', {itemname:t(modelExposedName)})}
                />
            }
            {...params}
        />
    </>
}

export default Create;



import * as Views from "./views";

const Module = {
    name: "persons",
    defaultRoutePath: "/people/person",
    menuItemScope: "people",
    menuItemName: "people",

    routes: [
        {
            path: "/people/person",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Views.Default,
                },
                {
                    path: "list",
                    Component: Views.List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: Views.List,
        },
        {
            path: "detail",
            Component: Views.Detail,
        },
        
        {
            path: "create",
            Component: Views.Create,
        },
        {
            path: "carAssociation",
            Component: Views.CarAssociation,
        },
        {
            path: "carDissociation",
            Component: Views.CarDissociation,
        },
        {
            path: "poolAssociation",
            Component: Views.PoolAssociation,
        },
        {
            path: "serviceAssociation",
            Component: Views.ServiceAssociation,
        },
        {
            path: "serviceDissociation",
            Component: Views.ServiceDissociation,
        },
        {
            path: "employmentAssociation",
            Component: Views.EmploymentAssociation,
        },
        {
            path: "employmentDissociation",
            Component: Views.EmploymentDissociation,
        },
    ],
}

export default Module;

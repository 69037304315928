import React, {useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/car';
const wizardName = 'Service dissociation';
const wizardObject = 'Service';
const wizardAction = 'serviceDissociation';

const ServiceDissociation = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);


     // DATA PROVIDER
     const {
        item,
        loadOne,
        errorMessage,
        errors,
        saving,
        doAction
    } = useDataProvider(dataProviderName, params.multipart);

    useEffect(() => {
        loadOne(params?.car?.id);
    }, []);

    let dsvs = params.defaultSelectedValues;
    if (typeof dsvs == 'string') {
        dsvs = dsvs.split(',').map((dsv) => { return {'_id': dsv}});
    } else if (typeof dsvs == 'undefined') {
        dsvs = [];
    } else{
        dsvs = [dsvs];
    }

    const modelName = existingModules.modelName

    const detailConfig = {
        "tabs": [
            {
                "name": "dissociation",
                "fieldsets": [
                    {
                        "name": "dissociation",
                        "fields": {
                            "service_assigned": {
                                "type": "sectiontitle",
                                "className": "col-12"
                            },
                            "dissociated": {
                                "type": "servicelist",
                                "defaultSelectedValues": dsvs,
                                "enableSelection": true,
                                "disableMultipleRowSelection": true,
                                "dataProviderName": "cars/service",
                                "filters": {
                                    "items": [
                                        {
                                            "field": "_id",
                                            "operator": "isAnyOf",
                                            "value": item?.data?.services?.map((service) => service._id).flat(Infinity)
                                        }
                                    ]
                                },
                                "className": "col-12",
                                "required": "true"
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }
    const { save: saveJob } = useDataProvider('queues/job');
    const handleSave = async (data) => {
        doAction && await doAction({ action: wizardAction, model: dataProviderName, data: data }).then();
        close();
    }

    return <>
        <DetailComponent
            values={{ "id": item?.data?._id, "dissociated": dsvs?.map((dsv) => dsv._id) }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.'+wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Remove a {{wizardObject}} from {{plate}}', { wizardObject:wizardObject, plate: item.data.plate })}
                />
            }
            {...params}
        />
    </>
}

export default ServiceDissociation;



import { Default, Detail, List,  Create } from "./views";



const Module = {
    name: "profitcenters",
    defaultRoutePath: "/legalentities/profitcenter",
    menuItemScope: "legalentities",
    menuItemName: "profitcenters",

    routes: [
        {
            path: "/legalentities/profitcenter",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Default,
                },
                {
                    path: "list",
                    Component: List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: List,
        },
        {
            path: "detail",
            Component: Detail,
        },
        
        {
            path: "create",
            Component: Create,
        },
    ],

}

export default Module;

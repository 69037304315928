
export const handleSuccess = (response) => {

    return Promise.resolve(response.data)

}

export const handleError = (error) => {

    if (error?.response?.status === 401) {
        sessionStorage.removeItem('token');
        window.location.reload(true);
    }
    return Promise.reject(error.response.data)
}


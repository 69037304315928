import React from 'react';
import { Box, ModalClose } from "@mui/joy"
import {Text} from '../../../../mtska-frontend-app-component';


const Install = (params) => {
   return (
      <>
         {params.isDrawer && (
            <Box className="drawer-section-header" >
               <ModalClose />
               <Text level="title-md" icon >Install</Text>
               {/* <Button  label="Install"  className="button-main" /> */}
            </Box>
         )}

         <Box className="drawer-section-content">
         Install wizard
         <div>
            {JSON.stringify(params.filters.length)} elementi selezionati
         </div>
         </Box>
      </>)
}


export default Install;



import React from 'react';
import { Box, List, ListItem } from '@mui/joy';
import { CellCountableMore } from './';
import { useTranslation } from 'react-i18next';

const CellCars = ({ value, limit = 1 }) => {
    if (!Array.isArray(value) && value) {
        value = [value];
    } else if (value == null) {
        value = [];
    }
    const length = Object.keys(value).length;
    const { t } = useTranslation();

    return (
        <Box className={"list-element-cell cell-cars"}>
            <List className="cell-cars-list">
                {value.map((car, k) => {
                    if (k >= limit) return;
                    return (
                        <ListItem key={k} className={'cell-cars-list-item' + (!car.plate && ' missing')}>
                            <span className={"name"} title={car.plate ? car.plate : t('Car plate not yet assigned')}>{car.plate ? car.plate : t('Plate not assigned')}</span>
                        </ListItem>
                    );
                })}
                <CellCountableMore limit={limit} length={length} />
            </List>
        </Box>
    );
}

export default CellCars;
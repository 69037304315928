import React, {useEffect, useContext, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/car';
const wizardName = 'Pool dissociation';
const wizardObject = 'Pool';
const wizardAction = 'poolDissociation';

const PoolDissociation = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();

    const { userCapabilities, existingModules } = useContext(AppContext);

    // DATA PROVIDER
    const {
        item,
        loadOne,
        errorMessage,
        errors,
        saving,
        doAction,
        success,
        
    } = useDataProvider(dataProviderName, params.multipart);

    useEffect(() => {
        loadOne(params?.car?.id);
    }, []);


    let dsvs = params.defaultSelectedValues;
    if (typeof dsvs == 'string') {
        dsvs = dsvs.split(',').map((dsv) => { return { '_id': dsv } });
    } else if (typeof dsvs == 'undefined') {
        dsvs = [];
    } else {
        dsvs = [dsvs];
    }

    const modelName = existingModules.modelName
    const detailConfig = {
        "tabs": [
            {
                "name": "dissociation",
                "fieldsets": [
                    {
                        "name": "dissociation",
                        "fields": {
                            "pool_assigned": {
                                "type": "sectiontitle",
                                "className": "col-12"
                            },
                            "dissociated": {
                                "type": "poollist",
                                "defaultSelectedValues": [],
                                "enableSelection": true,
                                "disableMultipleRowSelection": true,
                                "dataProviderName": "cars/pool",
                                "filters": {
                                    "items": [
                                        {
                                            "field": "_id",
                                            "operator": "isAnyOf",
                                            "value": [item?.data?.pool?._id]
                                        }
                                    ]
                                },
                                "className": "col-12",
                                "required": "true"
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }
    const [editing, setEditing] = useState(false)



    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])

    const handleSave = async (data) => {
        doAction && await doAction({ action: wizardAction, model: dataProviderName, data: data }).then();
    }

    return <>
        <DetailComponent
            values={{ "id": item?.data?._id, "dissociated": dsvs?.map((dsv) => dsv._id) }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            onEditing={setEditing}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.'+wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Remove {{carIdentifier}} from a {{wizardObject}}', { wizardObject:wizardObject, carIdentifier: (item.data.plate ? item.data.plate : item.data.setup?.make+" "+item.data.setup?.model) })} 
                />
            }
            {...params}
        />
    </>
}

export default PoolDissociation;



import React from "react";
import { List, ListItem, Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { PersonAvatar, LegalEntityAvatar, Tag } from "../Snippet";
import { Anchor } from "../Typography";
import { Input } from "../FormElements";

const InfoHeaderLegalEntity = ({ view, item, control }) => {
    const { t, i18n } = useTranslation();
    const dateformatter = Intl.DateTimeFormat(i18n.resolvedLanguage);

    return (
        <Box className={"info-header info-header-" + view}>
            <LegalEntityAvatar name={item.legalEntity} url={item.legalEntityLogo} size="big" />
            <Box className={"info-wrapper"}>
                <Box className="info-legal-entity">
                    {item.holding}
                </Box>
                <Box className="info-name">
                    {item.legalEntity} <span className="secondary">{item.legalEntityType}</span>
                </Box>
                <Box className="info-appandcompanyroles">
                    {
                        item.roles.map((vr) => (
                            <Tag color={"secondary"} size="medium" label={t(vr)} />
                        ))
                    }
                    <Tag color={"neutral"} size="medium" label={t(item.legalEntityRole)} />
                </Box>
                <List className="info-contacts">
                    <ListItem className="info-contacts-web">
                        <Anchor href={item.web} icon="faGlobe" iconfamily='fal'>{item.web.replace('https://', '')}</Anchor>
                    </ListItem>
                    <ListItem className="info-contacts-email">
                        <Anchor href={"mailto:" + item.email} icon="faEnvelope" iconfamily='fal'>{item.email}</Anchor>
                    </ListItem>
                    <ListItem className="info-contacts-telephone">
                        <Anchor href={"tel:" + item.telephone} icon="faPhone" iconfamily='fal'></Anchor>
                    </ListItem>
                    {item.hasWhatsapp && (
                        <ListItem className="info-contacts-whatsapp">
                            <Anchor href={"https://wa.me/" + item.telephone.replace(/[\+\ \-\_]/g, '')} icon="faWhatsapp" iconfamily='fab'></Anchor>
                        </ListItem>
                    )}
                    {item.hasTelegram && (
                        <ListItem className="info-contacts-telegram">
                            <Anchor href={"https://t.me/" + item.telephone.replace(/[\+\ \-\_]/g, '')} icon="faTelegram" iconfamily='fab' className></Anchor>
                        </ListItem>
                    )}
                </List>
            </Box>
            <Box className={"info-activity-wrapper"}>
                <Input
                    type="switch"
                    name={"active"}
                    control={control}
                    ischecked={item.active}
                    editing={true}
                    required={false}
                    checkedlabel={t("active")}
                    uncheckedlabel={t("inactive")}
                />
                <Box className={"info-lastlogin"}>
                    <Box className="pair">
                        <span className="key">
                            {t('Vehicles')}
                        </span>
                        <span className="value">
                            {item.vehicleCount}
                        </span>
                    </Box>
                    <Box className="pair">
                        <span className="key">
                            {t('Drivers')}
                        </span>
                        <span className="value">
                            {item.driverCount}
                        </span>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default InfoHeaderLegalEntity;
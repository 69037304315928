import React from 'react';
import { Box, List, ListItem } from '@mui/joy';
import { CellCountableMore } from './';

const CellDrivers = ({ value, limit = 1  }) => {
    if (!Array.isArray(value) && value) {
        value = [value];
    } else if (value == null) {
        value = [];
    }
    const length = Object.keys(value).length;

    return (
        <Box className={"list-element-cell cell-drivers"}>
            <List className="cell-drivers-list">
                {value.map((driver, k) => {
                    if (k >= limit) return;
                    return (
                        <ListItem key={k} className={'cell-drivers-list-item'}>
                            <span>{JSON.stringify(driver)}</span>
                            <span className="name" title={driver.plate}>{driver.plate}</span>
                        </ListItem>
                    );
                })}
                <CellCountableMore limit={limit} length={length} />
            </List>
        </Box>
    );
}

export default CellDrivers;
import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'persons/person';
const wizardName = 'Service dissociation';
const wizardObject = 'Service';
const wizardAction = 'serviceDissociation';

const ServiceDissociation = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);


    // DATA PROVIDER
    const {doAction, item, loadOne, errorMessage, errors, saving, success} = useDataProvider(dataProviderName, params.multipart);

    let dsvs = params.defaultSelectedValues;
    if (typeof dsvs == 'string') {
        dsvs = dsvs.split(',').map((dsv) => { return {'_id': dsv}});
    } else if (typeof dsvs == 'undefined') {
        dsvs = [];
    } else{
        dsvs = [dsvs];
    }
    const modelName = existingModules[dataProviderName].modelName
    const detailConfig = {
        "tabs": [
            {
                "name": "dissociation",
                "fieldsets": [
                    {
                        "name": "dissociation",
                        "fields": {
                            "service_assigned": {
                                "type": "sectiontitle",
                                "className": "col-12"
                            },
                            "dissociated": {
                                "type": "servicelist",
                                "defaultSelectedValues": dsvs,
                                "enableSelection": true,
                                "disableMultipleRowSelection": true,
                                "dataProviderName": "cars/service",
                                "filters": {
                                    "items": [
                                        {
                                            "field": "_id",
                                            "operator": "isAnyOf",
                                            "value": item?.data?.employments.map((employment) => employment.services.map((service) => service._id)).flat(Infinity)
                                        }
                                    ]
                                },
                                "className": "col-12",
                                "required": "true"
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])

    const handleSave = async (data) => {
        doAction && await doAction({ action: wizardAction, model: dataProviderName, data: data });
        close();
    }

    return <>
        <DetailComponent
            values={{ "id": item?.data?._id, "dissociated": dsvs?.map((dsv) => dsv._id) }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            onEditing={setEditing}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.'+wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Remove a {{wizardObject}}  from {{firstname}} {{lastname}}', { wizardObject:wizardObject, firstname: item.data.firstname, lastname: item.data.lastname })}
                />
            }
            {...params}
        />
    </>
}

export default ServiceDissociation;



import { Default, Detail, List } from "./views";



const Module = {
    name: "users",
    defaultRoutePath: "/people/user",
    menuItemScope: "people",
    menuItemName: "users",

    routes: [
        {
            path: "/people/user",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Default,
                },
                {
                    path: "list",
                    Component: List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: List,
        },
        {
            path: "detail",
            Component: Detail,
        },
    ],
   
}

export default Module;

import { React } from "react";
import { Provider as ReduxProvider } from 'react-redux'
import { store } from "./redux/store";

export const DataProvider = ({ children }) => {

    return (
        <ReduxProvider store={store}>
                {children}
        </ReduxProvider>
    );
};


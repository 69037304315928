import React, { useState } from "react";
import { pdfjs, Document, Page } from 'react-pdf';
import { Box } from '@mui/joy';
import { Anchor } from "../Typography";
import { useTranslation } from 'react-i18next';
import { Button } from "../Button";

pdfjs.GlobalWorkerOptions.workerSrc = '/worker/pdf.worker.min.js';

const WidgetDocumentPreview = ({ item }) => {
    const { t } = useTranslation();
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = (document) => {
        console.info(document);
        setNumPages(document._pdfInfo.numPages);
    }
    const handlePreviousPage = () => {
        setPageNumber(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setPageNumber(prevPage => Math.min(prevPage + 1, numPages));
    };

    return (
        <Box className="form-element form-element-preview-widget col-12">
            <Anchor href={item.url} target="_blank" level="body-small" icon="faDownload" className={"download_document"}>{t('Download')}</Anchor>
            {
                item.extension === 'pdf' && (
                    <Box className='preview-pdf'>
                        <Document file={item.url} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumber} />
                        </Document>

                        <Box className="paginator">
                            <Button onClick={handlePreviousPage} disabled={pageNumber <= 1} icon="faArrowLeft" className={'paginator-previous'}>
                                {t('Previous')}
                            </Button>

                            <span className="paginator-info">{t('Page {{pageNumber}} of {{numPages}}', {pageNumber:pageNumber, numPages:numPages})} </span>
                            <Button onClick={handleNextPage} disabled={pageNumber >= numPages} icon="faArrowRight" className={'paginator-next'}>
                                {t('Next')}
                            </Button>
                        </Box>
                    </Box>
                )
            }
            {
                ['png', 'jpg', 'jpeg'].includes(item.extension) && (
                    <Box className='preview-image'>
                        <img src={item.url} width={500} />
                    </Box>
                )
            }
        </Box>
    );
}

export default WidgetDocumentPreview;
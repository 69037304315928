import React, {useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/car';
const wizardName = 'Status Management';
const wizardObject = 'Status';
const wizardAction = 'statusManagement';

const StatusManagement = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();

    const { userCapabilities, existingModules } = useContext(AppContext);


     // DATA PROVIDER
     const {
        item,
        loadOne,
        errorMessage,
        errors,
        saving,
        doAction
    } = useDataProvider(dataProviderName, params.multipart);

    useEffect(() => {
        loadOne(params?.car?.id);
    }, []);

    const modelName = existingModules.modelName
    const detailConfig = {
        "tabs": [
            {
                "name": "management",
                "fieldsets": [
                    {
                        "name": "management",
                        "fields": {
                            "status": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "in_configuration",
                                        "value": "in_configuration"
                                    },
                                    {
                                        "label": "in_order",
                                        "value": "in_order"
                                    },
                                    {
                                        "label": "running",
                                        "value": "running"
                                    },
                                    {
                                        "label": "closed",
                                        "value": "closed"
                                    }
                                ],
                                "required": true,
                                "className": "col-12",
                                "placeholder": "select",
                                "icon": "faChartNetwork"
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }
    const handleSave = async (data) => {
        doAction && await doAction({ action: wizardAction, model: dataProviderName, data: data }).then();
        close();
    }

    return <>
        <DetailComponent
            values={{ "id": item?.data?.id, "status": item?.data?.status }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.'+wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Select a {{wizardObject}} to be associated to {{carIdentifier}}', { wizardObject:wizardObject, carIdentifier: (item.data.plate ? item.data.plate : item.data.setup?.make+" "+item.data.setup?.model) })} 
                />
            }
            {...params}
        />
    </>
}

export default StatusManagement;



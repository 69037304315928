
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton as JoyIconButton } from "@mui/joy"
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'

export default function IconButton({ disabled, onClick, className = '', icon, family = 'fal' }) {
    return (
        <JoyIconButton className={className} disabled={disabled} onClick={onClick}>
            <FontAwesomeIcon icon={(family == 'fal') ? fal[icon] : fas[icon]} />
        </JoyIconButton>
    )
}

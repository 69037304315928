import { Create,  Detail,   List } from "./views";

const Module = {
    name: "imports",
    defaultRoutePath: "/imports",

    routes: [
        
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: List,
        },
        {
            path: "detail",
            Component: Detail,
        },
        {
            path: "create",
            Component: Create,
        },
    ],
   
}

export default Module;

import React, { useState, useRef, useEffect } from 'react';
import { useForm, useWatch } from "react-hook-form"
import * as XLSX from 'xlsx';
import { Box, Alert } from '@mui/joy';
import Previewer from './Previewer';
import { useTranslation } from 'react-i18next';
import { Button, Input, Text } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';

const Importer = ({ dataProviderName, moduleName, multipart, isDrawer, uid, handleClose, filters, rightButtons, title, label, icon = "" }) => {

    const { t } = useTranslation();
    const { item: config } = useDataProvider('configs/config');
    const { save: saveImport, loadAll, errorMessage, errors, success } = useDataProvider('imports/job');
    const { loadAll: loadMappings, items: mappingsDb } = useDataProvider('imports/mapping');
    const { loadAll: loadLegalEntities, items: legalEntities } = useDataProvider('companies/legalentity');

    useEffect(() => {

        if (!Array.isArray(mappingsDb)) return;
        if (mappingsDb.length === 0) return;
        const mappingValues = mappingsDb.reduce((a, v) => {
            return { ...a, [v.name]: v.mapping };
        }, {});
        setMappings(mappingValues);
    }, [mappingsDb]);

    useEffect(() => {
        loadMappings(filters);
        loadLegalEntities({
            'items': [{
                'field': 'type',
                'operator': 'equals',
                'value': 'customer'
            }]
        });
    }, []);


    const [model, setModel] = useState();

    useEffect(() => {
        if (typeof config?.data?.modules === 'undefined') return;
        if (typeof config.data.modules[dataProviderName] === 'undefined') return;
        setModel(config.data.modules[dataProviderName].importModel);
    }, [config, dataProviderName])

    const [mappings, setMappings] = useState({});


    const form = useRef();
    const { handleSubmit, control, setValue } = useForm();
    const selectedMappingNewNameWatcher = useWatch({
        control,
        name: 'selectedMappingNewName'
    });

    const selectedMappingWatcher = useWatch({
        control,
        name: 'selectedMapping'
    });

    const selectedFileWatcher = useWatch({
        control,
        name: 'fileUploader'
    });

    useEffect(() => {
        setSelectedMappingNewName(selectedMappingNewNameWatcher);
    }, [selectedMappingNewNameWatcher])

    useEffect(() => {
        handleSelectedMapping(selectedMappingWatcher);
    }, [selectedMappingWatcher])

    useEffect(() => {
        if (document.querySelector('[name="fileUploader"]') === null) {
            return;
        }

        handleFile(document.querySelector('[name="fileUploader"]').files[0]);
    }, [selectedFileWatcher])

    const [excelFile, setExcelFile] = useState(null);
    const [error, setError] = useState(null);
    const [excelData, setExcelData] = useState(null);
    const [importStage, setImportStage] = useState(1);

    const [editing, setEditing] = useState(false);
    const [mapping, setMapping] = useState({});
    const [selectedMapping, setSelectedMapping] = useState();
    const [defaultMapping, setDefaultMapping] = useState({});

    const [selectedMappingNewName, setSelectedMappingNewName] = useState('');
    const [ submitDisabled, setSubmitDisabled] = useState(false);

    useEffect(() => {
        if (excelFile !== null) {
            const workbook = XLSX.read(excelFile, {
                type: 'buffer',
                // cellText:false, 
                //cellDates:true,
                raw: true,
            });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            // https://docs.sheetjs.com/docs/api/utilities/array#array-output
            const data = XLSX.utils.sheet_to_json(worksheet, {
                defval: "",
                raw: false,
                // dateNF: 'DD"/"MM"/"yyyy porcodio', 
                // strip: false, 
                blankrows: true
            });

            setExcelData(data);
            setImportStage(2);
        }
    }, [excelFile])

    // useEffect(() => {
    //     setValue('mapping', JSON.stringify(mapping));
    // }, [mapping]);

    // useEffect(() => {
    //     setValue('mappingreverse', JSON.stringify(mappingFieldsValue));
    // }, [mappingFieldsValue]);

    // --------
    // CARICAMENTO DEL FILE
    // --------
    const handleFile = (selectedFile) => {
        const validMIME = ['application/vnd.ms-excel', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

        if (selectedFile && validMIME.includes(selectedFile.type)) {
            setError(null);
            const reader = new FileReader();
            reader.readAsArrayBuffer(selectedFile);
            reader.onload = (e) => {
                setExcelFile(e.target.result);
            }
        } else if (selectedFile) {
            setError(t('Import source file must be a valid file type (xls, xlsx, csv)'));
            setExcelFile(null);
        } else {
            setError(null);
            setExcelFile(null);
        }
    }

    // --------
    // GESTIONE DEI DUE STATI DI SUBMIT DELLA FORM
    // --------
    const handleFileSubmit = (data) => {
        if (Object.keys(mapping).length === 0) {
            setError(t('At least one column must be associated to a destinationfield'));
        } else {
            submit(data);
            setSubmitDisabled(true);
        }
    }


    const submit = (data) => {
        console.log('Data in uscita', data)

        // const mappedExcelData = excelData.map((row) => {
        //     const mappedItem = {};
        //     for (const [key, value] of Object.entries(mapping)) {
        //         console.log("MAPPING", mapping, row, key, value, mappedItem);
        //         mappedItem[value] = row[key];
        //     }

        //     return mappedItem;
        // });

        setEditing(true);
        saveImport({ ...data, excelData: excelData, model: dataProviderName });
    }

    // --------
    // SELEZIONE DI UNA RICETTA DI MAPPING ESISTENTE
    // la selezione di un mapping esistente deve resettare eventuali mapping manuali e caricare lo schema desiderato
    // --------
    const handleSelectedMapping = (v) => {

        const newDefaultMapping = getMappingFromId(v);
        if (typeof newDefaultMapping === 'undefined') {
            setSelectedMapping(null);
            setDefaultMapping({});
            return;
        }

        setSelectedMapping(v);
        setDefaultMapping(getMappingFromId(v).mapping ?? {})
    }

    const getMappingsOptions = () => {
        const options = Object.keys(mappingsDb).length > 0 ? Object.values(mappingsDb).map((v, k) => {
            return { value: v.id, label: v.fullName };
        }) : []
        return options;
    }

    const getMappingFromId = (id) => {
        return mappingsDb.find(obj => {
            return obj.id === id
        })

    }

    const handleOnMappingChange = (newMapping) => {
        setValue('mapping', newMapping);
        setMapping(newMapping);
    }

    useEffect(() => {
        if (success && editing) {

            (async () => {
                try {
                    await handleClose(uid);
                    setEditing(false);
                    loadAll();
                } catch (e) { }
            })()

        }
    }, [success, editing])


    return (
        <Box className="drawer-section-content">

            <Box className="importer">
                <Box className="importer-form-wrapper">

                    <Box ref={form} className="importer-form" >

                        <Input
                            type="hidden"
                            name="mapping"
                            defaultValue={JSON.stringify(mapping)}
                            control={control}
                            editing={true}
                        />

                        {importStage === 1 && (
                            <Box className={((importStage === 1) ? "import-stage import-stage-visible" : "import-stage import-stage-hidden")}>
                                <Text level={"title-md"} className="sectionTitle">{t('Select a file to be imported')}</Text>
                                <Input
                                    label={'Upload a file'}
                                    type="file"
                                    name="fileUploader"
                                    control={control}
                                    editing={true}
                                />
                            </Box>
                        )}
                        {importStage === 2 && (
                            <Box className={((importStage === 2) ? "import-stage import-stage-visible" : "import-stage import-stage-hidden")}>
                                <Text level={"title-md"} className="sectionTitle">{t('Select an existing mapping to be applied')} </Text>
                                <Box className="import-actions">
                                    {mappings && Object.keys(mappings).length > 0 && (
                                        <Input
                                            label={'Apply saved mapping'}
                                            type="select"
                                            name="selectedMapping"
                                            control={control}
                                            editing={true}
                                            addEmptyOption={true}
                                            options={getMappingsOptions()}
                                            translateOptions={false}
                                        />
                                    )}

                                    <Input
                                        label={selectedMapping ? 'Rename mapping as' : 'Save mapping as'}
                                        type="text"
                                        name="selectedMappingNewName"
                                        control={control}
                                        editing={true}
                                    />
                                    <Input
                                        label={'Save data for LegalEntity'}
                                        type="select"
                                        name="legal_entity_id"
                                        control={control}
                                        translateOptions={false}
                                        editing={true}
                                        options= {
                                            Array.isArray(legalEntities) && legalEntities.map((legalentity, k) => (
                                                { value : legalentity.id, label:legalentity.name }
                                            ))
                                        }
                                    />
                                    <Box className="form-element">
                                        <Button variant="solid" className="importer-submit" onClick={() => { handleSubmit(handleFileSubmit)() }} disabled={submitDisabled}>{t('Import')}</Button>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
                {error && (
                    <Alert className="importer-error">{error}</Alert>
                )}

                {importStage === 2 && (
                    <Previewer
                        excelData={excelData}
                        model={model}
                        defaultMapping={defaultMapping}
                        onMappingChange={handleOnMappingChange}
                    />
                )}

            </Box>
        </Box>
    );
}

export default Importer;
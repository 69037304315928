import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { AppContext } from '../../../contexts';
import { useStyleVariableInjector } from 'src/mtska-frontend-app-scaffolding/utils/StyleVariableInjector';


const Minimal = () => {
    const { appConfiguration } = useContext(AppContext);
    const { injectColorsToDocumentNode } = useStyleVariableInjector();

    injectColorsToDocumentNode(appConfiguration.colors);

    return (
        <div className="app-login">
            <main ><Outlet /></main>
        </div>
    );
};

export default Minimal;

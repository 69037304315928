import { Default, Detail,  List, Create } from "./views";

const Module = {
    name: "shops",
    defaultRoutePath: "/commercials/shop",
    menuItemScope: "operations",
    menuItemName: "shop",

    routes: [
        {
            path: "/commercials/shop",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Default,
                },
                {
                    path: "list",
                    Component: List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: List,
        },
        {
            path: "detail",
            Component: Detail,
        },
        
        {
            path: "create",
            Component: Create,
        },
    ],

}

export default Module;

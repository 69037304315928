import { Create, Default, Detail,   List } from "./views";


const Module = {
    name: "fringebenefitvalues",
    defaultRoutePath: "/cars/fringebenefitvalue",
    menuItemScope: "fleet",
    menuItemName: "fringebenefitvalues",

    routes: [
        {
            path: "/cars/fringebenefitvalue",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Default,
                },
                {
                    path: "list",
                    Component: List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: List,
        },
        {
            path: "detail",
            Component: Detail,
        },
        {
            path: "create",
            Component: Create,
        },
        
    ],
    
}

export default Module;

export const useListFiltersAdapter = () => {

    const adaptRelationshipFilters = (filters, relation, formState) => {

        // Recupero da config eventuali filtri e li traduco per la lista
        if (relation?.filter) {
            if (typeof filters == 'undefined') {
                filters = {};
                filters.items = [];
            }

            filters.items = relation?.filter.items.map((item) => {
                if (item.value?.includes('formState.')) {
                    const newitem = {};
                    newitem.field = item.field;
                    newitem.operator = item.operator;
                    newitem.value = item.value.split('.').reduce((acc, key) => acc && acc[key], { formState });

                    return newitem;
                }
                return item;
            });
        }

        return filters;
    }

    return {
        adaptRelationshipFilters
    }
};

import React from 'react';
import { Box, List, ListItem } from '@mui/joy';

const CellPerson = ({ value }) => {

    return (
        (value && (
                <Box className={"list-element-cell cell-person"}>
                    <span className="name" title={value?.firstname + " " + value?.lastname}>{(value.firstname + " " + (value.lastname ?? '')).trim()}</span>
                </Box>
            ) 
        )
    );
}

export default CellPerson
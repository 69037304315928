import createConstants from "./constants";
import createService from "./service";


export const createAction = (dataProviderName, uid, multipart) => {

    const service = createService(dataProviderName, multipart);
    const constants = createConstants(dataProviderName);


    function download() {
        return dispatch => {
            dispatch(request());
            return service.download()
                .then(
                    () => dispatch(success()),
                    error => dispatch(failure(error))
                );
        };

        function request() {
            return { dataProviderName: dataProviderName, uid, type: constants.DOWNLOAD_REQUEST }
        }
        function success() {
            return { dataProviderName: dataProviderName, uid, type: constants.DOWNLOAD_SUCCESS }
        }
        function failure(error) {
            return { dataProviderName: dataProviderName, uid, type: constants.DOWNLOAD_FAILURE, error }
        }
    }

    function destroy(id) {
        return dispatch => {
            dispatch(request());
            return service.remove(id)
                .then(
                    () => dispatch(getAll()),
                    error => dispatch(failure(error))
                );
        };

        function request() {
            return { dataProviderName, uid, type: constants.DELETE_REQUEST }
        }
        function success(id) {
            return { dataProviderName, uid, type: constants.DELETE_SUCCESS, id }
        }
        function failure(id, error) {
            return { dataProviderName, uid, type: constants.DELETE_FAILURE, id, error }
        }
    }

    function doAction(item) {

        return dispatch => {
            dispatch(request());

            return service.doAction(item)
                .then(
                    item => dispatch(success(item)),
                    error => dispatch(failure(error))
                );
        };

        function request() {
            return { dataProviderName, uid, type: constants.ACTION_REQUEST }
        }
        function success(item) {
            return { dataProviderName, uid, type: constants.ACTION_SUCCESS, item }
        }
        function failure(error) {
            return { dataProviderName, uid, type: constants.ACTION_FAILURE, ...error };
        }


    }


    function save(item) {

        return dispatch => {
            dispatch(request());

            return service.save(item)
                .then(
                    res => dispatch(success(res, item.canReloadCounter)),
                    error => dispatch(failure(error))
                );
        };

        function request() {
            return { dataProviderName, uid, type: constants.SAVE_REQUEST }
        }
        function success(item, canReloadCounter) {
            return { dataProviderName, uid, type: constants.SAVE_SUCCESS, item, canReloadCounter }
        }
        function failure(error) {
            return { dataProviderName, uid, type: constants.SAVE_FAILURE, ...error };
        }


    }


    function getAll(filterModel = {}, sortModel = {}, page = '', per_page = '') {
        return dispatch => {
            dispatch(request());

            return service.getAll(filterModel, sortModel, page, per_page)
                .then(
                    items => dispatch(success(items)),
                    error => dispatch(failure(error))
                );
        };

        function request() {
            return { dataProviderName, uid, type: constants.GETALL_REQUEST }
        }
        function success(items) {
            return { dataProviderName, uid, type: constants.GETALL_SUCCESS, items }
        }
        function failure(error) {
            return { dataProviderName, uid, type: constants.GETALL_FAILURE, ...error };
        }
    }

    function getOne(id) {
        return dispatch => {

            dispatch(request(id));

            return service.getById(id)
                .then(
                    item => dispatch(success(item)),
                    error => dispatch(failure(error))
                );
        };

        function request(id) {
            return { dataProviderName, uid, type: constants.GETONE_REQUEST, id }
        }

        function success(item) {
            return { dataProviderName, uid, type: constants.GETONE_SUCCESS, item }
        }

        function failure(error) {
            return { dataProviderName, uid, type: constants.GETONE_FAILURE, id, error }
        }
    }
    function reset() {
        return dispatch => {
            dispatch(success());
        };

        function request() {
            return { dataProviderName, uid, type: constants.RESET_REQUEST }
        }
        function success() {
            return { dataProviderName, uid, type: constants.RESET_SUCCESS }
        }
        function failure(error) {
            return { dataProviderName, uid, type: constants.RESET_FAILURE, ...error };
        }
    }

    return {
        save,
        doAction,
        getAll,
        getOne,
        reset,
        destroy,
        download,
    };

}

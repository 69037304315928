import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, SectionTitleEmbedded, Tag } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/pool';


const CarsOfPool = (params) => {

    const { t } = useTranslation();
    const { existingModules } = useContext(AppContext);


    // DATA PROVIDER
    const { item, save, loadOne, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);

    const modelName = existingModules[dataProviderName].modelName
    const detailConfig = {
        "tabs": [
            {
                "name": "pool",
                "fieldsets": [
                    {
                        "name": "pool_information",
                        "fields": {
                            "cars": {
                                "type": "relationship",
                                "relation": {
                                    "drawerRoute": "cars",
                                    "inverseRelation": "pool",
                                    "inverseRelationType": "relationship",
                                    "limit": 1000
                                },
                                "view": "car"
                            }
                        }
                    }
                ]
            }
        ],
        "validation": []
    }
    // load from dataProvider
    useEffect(() => {
        loadOne(params.filters?._id);
    }, []);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])

    const handleSave = (data) => {
        save && save({ ...data, filters: params.filters });
    }

    return <>
        {
            item &&
            <>
                <SectionTitleEmbedded 
                    label={ t('Cars in the association') + ' "' +item?.data?.name + '"'} 
                    className={'col-12'}
                    tag={
                        String(item?.data?.isPool) == 'true' ? (
                            <Tag label={t('pool')} color="secondary"/>
                        ) : (
                            <Tag label={t('direct')} color="success"/>
                        )
                    }
                />
                <DetailComponent
                    values={{ ...item?.data }}
                    modelName={modelName}
                    detailConfig={detailConfig}
                    onEditing={setEditing}
                    save={handleSave}
                    saving={saving}
                    errors={errors}
                    errorMessage={errorMessage}
                    isEmbedded={true}
                    {...params}
                />
            </>
        }
    </>
}

export default CarsOfPool;



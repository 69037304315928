import { useEffect, useState } from 'react'

export const useCustomFooter = ({ paginationModel = {}, onPageChange }) => {

    const { page, pageSize: pageSizeFromProps } = paginationModel

    const [state, setState] = useState({
        displayedItems: { end: 0, start: 0 },
        selectedPage: page + 1,
        pageSize: pageSizeFromProps
    })
    const { selectedPage, pageSize } = state

    useEffect(() => {
        setState(prev => ({ ...prev, selectedPage: page + 1 }))
    }, [page])

    useEffect(() => {
        setState(prev => ({ ...prev, pageSize: pageSizeFromProps }))
    }, [pageSizeFromProps])

    useEffect(() => {
        const end = (page + 1) * pageSize
        const start = (pageSize * page) || 1
        setState(prev => ({ ...prev, displayedItems: { end, start } }))
    }, [page, pageSize])

    const onGo = ({ page: newPage }) => {
        const page = (newPage ? newPage : selectedPage) - 1
        onPageChange({ page, pageSize })
    }

    const onSelectedPageChange = (event) => {
        const { value } = event.target
        const formattedValue = Number(value)
        setState(prev => ({ ...prev, selectedPage: formattedValue }))
        onGo({ page: formattedValue })
    }

    const onPageSizeChange = (event) => {
        const { value: pageSize } = event.target
        setState(prev => ({ ...prev, pageSize }))
        onPageChange({ page: 0, pageSize })
    }

    return { onGo, onPageSizeChange, onSelectedPageChange, state }
}

import React, {useMemo} from 'react';
import { Box } from '@mui/joy';
import { KPI } from '../../../../mtska-frontend-app-component';
import List from './List';

const Default = () => {

    return <>
        <Box className="mtksa-chart-wrapper">
            <KPI
                title="Shops"
                icon="faGauge"
                dataProviderName={'statistics/item/count/commercials/shop'}
            />
        </Box>
        <List />
    </>

}

export default Default;



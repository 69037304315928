import React, {useMemo} from 'react';
import { Box } from '@mui/joy';
import { KPI } from '../../../../mtska-frontend-app-component';
import List from './List';

const Default = () => {
    const now = new Date();
    const startMonth = now.getFullYear() + '-' + String(now.getMonth() + 1).padStart(2, '0') + '-01';

    return useMemo(() => <>
        <Box className="mtksa-chart-wrapper">
            <KPI
                title="Vehicles"
                icon="faCar"
                dataProviderName={'statistics/item/count/cars/car'}
            />
            <KPI
                title="Costs"
                icon="faMoneyBill"
                dataProviderName={'statistics/total/sumfinancial/cars/cost/valueTotal'}
                format="currency"
            />
            <KPI
                title="Costs Current Month"
                icon="faMoneyBill"
                dataProviderName={'statistics/monthly/sumfinancial/cars/cost/valueTotal'}
                filters={{'items':[{
                    'field':'invoiced_at',
                    'operator':'after',
                    'value':startMonth
                }]}}
                format="currency"
            />
           
        </Box>
        <List />
    </>,[])

}

export default Default;



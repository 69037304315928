import React, {useContext} from "react";
import { Box } from "@mui/joy";

import { useTranslation } from 'react-i18next'
import Termometer from "./Termometer";
import { useFormatter } from "../../utils";
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const TraceKmTermometer = ({ tracedate }) => {
    const { t } = useTranslation();
    const { formatDate } = useFormatter();
    const { appConfiguration } = useContext(AppContext);

    const addDays = (date, days) => {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    const traced        = new Date(tracedate);
    const td            = new Date();
    const nexttraced    = addDays(traced, parseInt(appConfiguration.custom.traceKmMaxMissingDays));

    const awaitenlapsed = Math.floor((td - traced) / (1000 * 60 * 60 * 24));
    const awaitnext     = Math.floor((nexttraced - traced) / (1000 * 60 * 60 * 24));

    const awaitenlapsedpercentage = awaitenlapsed / awaitnext;

    let color = "success";
    if (awaitenlapsedpercentage >= 1) {
        color = "danger"
    } else if (awaitenlapsedpercentage >= 0.7) {
        color = "warning"
    }

    return (
        <Box className="general-termometer">
            <Termometer percentage={awaitenlapsedpercentage} color={color} hint={t("Next detection expected at") + " " + formatDate(nexttraced)} hintposition="top" />
        </Box>
    )
}

export default TraceKmTermometer;

import { useEffect } from 'react'

export const useLoadAll = ({ listFilterPageSort = {}, loadAll = () => { }, params = {}, reloadCounter }) => {
    useEffect(() => {
        if (Object.keys(listFilterPageSort).length > 0) {
            const { filterModel = {}, paginationModel = {}, sortModel } = listFilterPageSort
            const { page = 0, pageSize = 20 } = paginationModel
            const newFilterModel = {
                ...filterModel,
                ...params.filters,
                items: [
                    ...(filterModel.items || []),
                    ...(params.filters?.items || [])
                ]
            }
            loadAll(newFilterModel, sortModel, page + 1, pageSize)
        }
    }, [listFilterPageSort, reloadCounter])
}
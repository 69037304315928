import { useNavigate } from 'react-router-dom'

export const useRouterAnchor = ({ href }) => {

    const navigate = useNavigate()

    const handleClick = (event) => {
        event.preventDefault()
        navigate(href)
    }

    return { handleClick }
}

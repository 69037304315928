import React, {useState, useEffect, useContext} from 'react';
import {Button, DetailTitle, useDrawer} from '../../../../mtska-frontend-app-component';
import {Box, ModalClose} from "@mui/joy"
import {useDataProvider} from '../../../../mtska-frontend-data-provider';
import {useTranslation} from 'react-i18next';
import {useLoadOne} from "../../../../mtska-frontend-data-provider/hooks/useLoadOne.hooks";
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'bis/bi';

const Detail = (params) => {

    const { isDrawer, uid, } = params;
    const { t } = useTranslation();
    const { close: closeDrawer } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);

    // DATA PROVIDER
    const { item, save, loadOne, destroy, errorMessage, errors, saving, success, reloadCounter } = useDataProvider(dataProviderName, params.multipart);

    const [detailConfig, setDetailConfig] = useState();
    const [modelName, setModelName] = useState();
    useEffect(() => {
        if (existingModules === undefined) return;
        if (typeof existingModules[dataProviderName] === 'undefined') return;
        setDetailConfig(existingModules[dataProviderName].detailConfig);
        setModelName(existingModules[dataProviderName].modelName);
    }, [existingModules, dataProviderName]);




    const [defaultValues, setDefaultValues] = useState({})

    useLoadOne({loadOne, params, reloadCounter})

    useEffect(() => {
        if (!item?.data) return
        setDefaultValues(item.data)
    }, [item])


    return (
        <>
            {isDrawer && (
                <Box className="drawer-section-header" >
                    <ModalClose />

                    <DetailTitle title={defaultValues?.name} />

                    <Box display={"flex"} flexDirection="row"
                        justifyContent={"end"}
                        gap={1}
                        flexGrow={1}>

                        <Button
                            onClick={(e) => {
                                const el = document.getElementById(uid);
                                if (el) el.requestFullscreen();
                            }}
                        >
                            {t("Full screen")}
                        </Button>
                    </Box>

                </Box>
            )}
            <Box padding={0}>
                <div style={{ height: "100vh", width: "100%" }}>

                    <iframe width="100%" height="100%" src={defaultValues?.url_embed}
                        frameborder="0"
                        style={{}}
                        allowfullscreen
                        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                        id={uid}
                    >
                    </iframe>
                </div>
            </Box>
        </>
    )

}

export default Detail;

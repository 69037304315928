import React, {useContext} from "react";
import { Box } from '@mui/joy';
import { Icon, KPI } from '../../../../mtska-frontend-app-component';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const Default = () => {
    const { t } = useTranslation();
    const { appConfiguration } = useContext(AppContext);

    return (
        <Box>
            <h1 style={{ marginTop: 0 }}>{appConfiguration.dashboard.title}</h1>
            <p>{appConfiguration.dashboard.subtitle}</p>
            <ul className="dashboard-menu">
                <li className="dashboard-menu-item">

                    <Icon icon="faDashboard" />
                    <h2 className="menu-item-title">{t('Business intelligence')}</h2>
                    <ul className="dashboard-submenu">
                        <li className="dashboard-submenu-item"><a href="/bis/biviewer">{t('Operational Report')}</a></li>
                        <li className="dashboard-submenu-item"><a href="https://eu-west-1a.online.tableau.com/#/site/engelvoelkers/home" target="_blank">{t('Tableau')}</a></li>
                    </ul>

                </li>
                <li className="dashboard-menu-item">

                    <Icon icon="faFilter" />
                    <h2 className="menu-item-title">{t('Operation')}</h2>
                    <ul className="dashboard-submenu">
                        <li className="dashboard-submenu-item"><a href="/opportunities/listing">{t('Listing')}</a></li>
                        <li className="dashboard-submenu-item"><a href="/opportunities/lead">{t('Leads')}</a></li>
                        <li className="dashboard-submenu-item"><a href="/opportunities/proposal">{t('Proposals')}</a></li>
                    </ul>

                </li>
                <li className="dashboard-menu-item dimmed">

                    <Icon icon="faMegaphone" />
                    <h2 className="menu-item-title">{t('Marketing')}</h2>
                    <ul className="dashboard-submenu">
                        <li className="dashboard-submenu-item hidden">{t('GPT')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Photo')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Matterport')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Content')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Newsletter')}</li>
                    </ul>

                </li>
                <li className="dashboard-menu-item dimmed">

                    <Icon icon="faMessageDollar" />
                    <h2 className="menu-item-title">{t('Finance')}</h2>
                    <ul className="dashboard-submenu">
                        <li className="dashboard-submenu-item hidden">{t('Costs')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Ebitda')}</li>
                    </ul>

                </li>
                <li className="dashboard-menu-item">

                    <Icon icon="faSuitcase" />
                    <h2 className="menu-item-title">{t('Sales support')}</h2>
                    <ul className="dashboard-submenu">
                        <li className="dashboard-submenu-item hidden">{t('Customer welcome kit')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Farming material')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Opportunities')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Sister interrogation')}</li>
                        <li className="dashboard-submenu-item"><a href="https://it.casafari.com/login" target="_blank">Casafari</a></li>
                        <li className="dashboard-submenu-item"><a href="https://salescontrolling.engelvoelkers.com/dashboard/start/res/shop" target="_blank">{t('Sales Tool')}</a></li>
                    </ul>

                </li>
                <li className="dashboard-menu-item dimmed">

                    <Icon icon="faPeople" />
                    <h2 className="menu-item-title">{t('Human Resources')}</h2>
                    <ul className="dashboard-submenu">
                        <li className="dashboard-submenu-item hidden">{t('Talent acquisition')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Talent management')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Internal communications')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Incentive')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Knowledge base')}</li>
                    </ul>

                </li>
                <li className="dashboard-menu-item">

                    <Icon icon="faCog" />
                    <h2 className="menu-item-title">{t('Settings')}</h2>
                    <ul className="dashboard-submenu">
                        <li className="dashboard-submenu-item"><a href="/commercials/area">{t('Areas')}</a></li>
                        <li className="dashboard-submenu-item"><a href="/commercials/shop">{t('Shops')}</a></li>
                        <li className="dashboard-submenu-item"><a href="/people/person">{t('Users')}</a></li>
                        <li className="dashboard-submenu-item"><a href="/commercials/areasource">{t('Goals')}</a></li>
                    </ul>

                </li>
            </ul>
        </Box>
    )

}

export default Default;



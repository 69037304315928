export const useString = () => {

    const firstCharacter = (input) => {
        if (typeof input === 'string' || input instanceof String) {
            return input.charAt(0)
        }
        return '*';
    }

    const toColor = (str) => {
        if (typeof str !== 'string') str = 'undefined';

        if (typeof str == typeof undefined) str = 'undefined';

        let hash = 0;
        str.split('').forEach(char => {
            hash = char.charCodeAt(0) + ((hash << 5) - hash)
        })
        let colour = '#'
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xff
            colour += value.toString(16).padStart(2, '0')
        }

        return colour
    }

    const toInverseColor = (str) => {
        let hex = toColor(str);
        // Rimuovi il '#' all'inizio se presente
        hex = hex.replace('#', '');

        // Inverti ogni componente RGB
        let invertedColor = '#';
        for (let i = 0; i < 3; i++) {
            const component = parseInt(hex.substring(i * 2, i * 2 + 2), 16);  // Converti componente da esadecimale a intero
            const invertedComponent = (255 - component).toString(16).padStart(2, '0');  // Inverti il valore e riconverti in esadecimale
            invertedColor += invertedComponent;
        }

        return invertedColor;
    };

    return {
        firstCharacter,
        toColor,
        toInverseColor
    }
};

import { useEffect, useRef, useCallback } from 'react'

export const useDebounce = (functions, delay = 700) => {
    const timeoutRef = useRef(null)

    const debouncedFunction = useCallback((...args) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }

        timeoutRef.current = setTimeout(() => {
            functions.forEach(({ callback, params }) => {
                callback(...(params || args))
            })
        }, delay)
    }, [functions, delay])

    useEffect(() => {
        return () => clearTimeout(timeoutRef.current)
    }, [delay])

    return debouncedFunction
}

import React from 'react';
import { useState, useEffect } from 'react';
import { Box, Table, Select, Option, Autocomplete } from '@mui/joy';

import { useTranslation } from 'react-i18next';
import { Confirm, Text } from '../../../../mtska-frontend-app-component';

const Previewer = ({ excelData, model, defaultMapping = {}, onMappingChange }) => {
    const { t } = useTranslation();
    const [previewLimit, setPreviewLimit] = useState(25);
    const [mapping, setMapping] = useState({});

    useEffect(() => {
        setMapping(defaultMapping);
    }, [defaultMapping])

    useEffect(() => {
        onMappingChange && onMappingChange(mapping);
    }, [mapping])


    const [hasMappingConflicts, setHasMappingConflicts] = useState(false);

    const applyMapping = (xlscol, newValue) => {

        setHasMappingConflicts(false);

        const newMapping = { ...mapping, [xlscol]: newValue };

        if (newValue === "") {
            delete newMapping[xlscol];
        }

        const previousMapping = Object.keys(mapping).find(key => mapping[key] === newValue);
        if (typeof previousMapping !== "undefined") {
            delete newMapping[previousMapping];
        }

        //console.log('applyMapping newMapping', newMapping);
        setMapping(newMapping);
    }

    const handleMappingChange = (e, newValue, xlscol) => {
        if (!newValue) return;

        const previousMapping = Object.keys(mapping).find(key => mapping[key] === newValue.value);
        //console.log('handleMappingChange newValue', newValue);
        //console.log('handleMappingChange xlscol', xlscol);
        //console.log('handleMappingChange mapping', mapping);
        //console.log('handleMappingChange previousMapping', previousMapping);

        if (typeof previousMapping === "undefined") {
            applyMapping(xlscol, newValue.value);
            return;
        }

        setHasMappingConflicts({ previousMapping: previousMapping, xlscol: xlscol, newValue: newValue.value })
    }

    const getPreviewRows = () => {
        const previewData = excelData.slice(0, previewLimit);

        return previewData.map((item, rowid) => (
            <tr key={rowid}>
                {
                    Object.keys(item).map((key) => (
                        <td key={key} title={item[key]}>{item[key]}</td>
                    ))
                }
            </tr>
        ));
    }

    return excelData && (
        <>
            <Text level={"title-md"} className="sectionTitle">{t('Preview of the first {{previewLimit}} rows out of {{totalRows}}', { previewLimit: previewLimit, totalRows: excelData.length})} </Text>
            <Box className="importer-data-wrapper">
                <Box className="importer-data">
                    <Table>
                        <thead>
                            <tr className="sourceHead">
                                {
                                    Object.keys(excelData[0]).map((key) => (
                                        <th key={key} title={key}>{key}</th>
                                    ))
                                }
                            </tr>
                            <tr className="mappingHead">
                                {
                                    Object.keys(excelData[0]).map((key) => (
                                        <th key={key} title={key}>
                                            <span>{t('Map field to')}</span>
                                            <Autocomplete
                                                name={key}
                                                onChange={(e, v) => handleMappingChange(e, v, key)}
                                                variant="outlined"
                                                className="form-element-field form-element-field-autocomplete"
                                                value={mapping[key] ? mapping[key] : ''}
                                                options={
                                                    Array.isArray(model) ? model.map((field, k) => {
                                                        return {key:k, value:field.key, label:field.label}
                                                    }) : []
                                                }
                                            />
                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                getPreviewRows()
                            }
                        </tbody>
                    </Table>
                    {hasMappingConflicts && (
                        <Confirm
                            oklabel={t("Change")}
                            okcallable={() => { applyMapping(hasMappingConflicts.xlscol, hasMappingConflicts.newValue) }}
                            kolabel={t("Cancel")}
                            kocallable={() => { setHasMappingConflicts(false) }}
                            title={t("Attribution conflict")}>
                                {t("The field")} <strong>{hasMappingConflicts.newValue}</strong> {t("is already assigned to column")} <strong>{hasMappingConflicts.previousMapping}</strong>, {t("do you want to assign to column")} <strong>{hasMappingConflicts.xlscol}</strong>?
                        </Confirm>
                    )}
                </Box>
            </Box>
        </>
    );
}

export default Previewer;
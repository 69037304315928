import React from "react"
import { Link } from '@mui/joy'
import { Icon } from '../../assets'
import { useRouterAnchor } from "./routerAnchor.hooks"

const RouterAnchor = ({ href, target = "_self", level = 'body-md', icon, iconfamily = 'fal', disabled, variant, underline, className, children }) => {
    className = (typeof className != typeof undefined) ? className + " mtska-link mtska-link-" + level : "mtska-link mtska-link-" + level
    if (children == null) {
        className += ' iconic'
    }

    const { handleClick } = useRouterAnchor({ href })

    return (
        <Link
            level={level}
            target={target}
            onClick={handleClick}
            disabled={disabled}
            variant={variant}
            underline={underline}
            className={className}
            startDecorator={(icon) ? (
                <Icon icon={icon} family={iconfamily} />
            ) : (
                <></>
            )}
        >
            {children}
        </Link>
    )
}

export default RouterAnchor
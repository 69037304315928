import { Default, Detail, List, Create } from "./views";



const Module = {
    name: "employments",
    defaultRoutePath: "/people/employment",
    menuItemScope: "people",
    menuItemName: "employments",

    routes: [
        {
            path: "/people/employment",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Default,
                },
                {
                    path: "list",
                    Component: List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: List,
        },
        {
            path: "detail",
            Component: Detail,
        },
        
        {
            path: "create",
            Component: Create,
        },
    ],
    
}

export default Module;

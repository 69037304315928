import React from 'react'
import { useCustomFooter } from './customFooter.hooks'
import { useTranslation } from 'react-i18next'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { Box } from '@mui/joy'
import { IconButton } from 'src/mtska-frontend-app-component/assets'
import { styles } from './customFooter.styles'

export const CustomFooter = ({ onPageChange = () => { }, pageSizeOptions, paginationModel = {}, rowCount }) => {

    const { t } = useTranslation()

    const { onGo, onPageSizeChange, onSelectedPageChange, state } = useCustomFooter({ paginationModel, onPageChange })

    const { displayedItems, selectedPage, pageSize } = state

    const { end, start } = displayedItems

    const lastPage = Math.ceil(rowCount / pageSize)

    const firstPage = 1
    const pageOptions = Array.from({ length: lastPage - firstPage + 1 }, (_, i) => firstPage + i)

    const currentPage = paginationModel.page + 1
    const isNextButtonDisabled = currentPage === lastPage
    const isPrevButtonDisabled = !selectedPage || (currentPage === 1)

    const showFooter = Boolean(selectedPage) && Boolean(lastPage)

    const { containerBoxSx, currentPageBoxSx, pageSizeBoxSx } = styles

    return (
        showFooter && <Box sx={containerBoxSx} className="list-footer">
            <InputLabel className="list-footer-rows-info">
                {t('Rows from')}
                <b> {start} </b>
                {t('to')}
                <b> {rowCount && end > rowCount ? rowCount : end} </b>
                {rowCount > 0 && <>
                    {t('of')}
                    <b> {rowCount} </b>
                </>}
            </InputLabel>
            <Box sx={pageSizeBoxSx} className="list-footer-rows-number">
                <InputLabel>
                    {t('Rows per page')}
                </InputLabel>
                <Select onChange={onPageSizeChange} size='small' value={pageSize} className="list-footer-select">
                    {pageSizeOptions.map(size => <MenuItem key={size} value={size}>{size}</MenuItem>)}
                </Select>
            </Box>
            <Box sx={currentPageBoxSx} className="list-footer-page-navigator">
                <IconButton
                    disabled={isPrevButtonDisabled}
                    family='faSolid'
                    icon="faAngleLeft"
                    onClick={() => onGo({ page: currentPage - 1 })}
                />
                <InputLabel>
                    {t('Page')}
                </InputLabel>
                <Select onChange={onSelectedPageChange} size='small' value={selectedPage} className="list-footer-select">
                    {pageOptions.map(size => <MenuItem key={size} value={size}>{size}</MenuItem>)}
                </Select>
                <InputLabel>
                    {t('of')}
                    <b> {lastPage} </b>
                </InputLabel>
                <IconButton
                    disabled={isNextButtonDisabled}
                    family='faSolid'
                    icon="faAngleRight"
                    onClick={() => onGo({ page: currentPage + 1 })}
                />
            </Box>
        </Box >
    )
}

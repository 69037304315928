import React from 'react';
import { Box, List, ListItem } from '@mui/joy';
import { CellCountableMore } from './';

const CellPeople = ({ value, limit = 4 }) => {
    if (!Array.isArray(value) && value) {
        value = [value];
    } else if (value == null) {
        value = [];
    }
    const length = (value) ? Object.keys(value).length : 0;

    const getFirstCharacter = (input) => {
        if (typeof input === 'string' || input instanceof String){
            return input.charAt(0)
        }
        return 'N';
    }

    return (
        <Box className={"list-element-cell cell-people"}>
            <List className="cell-people-list">
                {value.map((person, k) => {
                    if (k >= limit) return;
                    return (
                        <ListItem key={k} className={'cell-people-list-item'}>
                            <span className="name" title={person.firstname + " " + person.lastname}>{getFirstCharacter(person.firstname) + getFirstCharacter(person.lastname)}</span>
                        </ListItem>
                    );
                })}

                <CellCountableMore limit={limit} length={length} />
            </List>
        </Box>
    );
}

export default CellPeople
import React from 'react';
import { Box } from '@mui/joy';
import { LegalEntityAvatar } from  '../Snippet'

const CellLegalEntityFlat = ({nameField, iconField}) => {

    return (
        <Box className={"list-element-cell cell-legalentity"}>
            {
                nameField && <LegalEntityAvatar  name={nameField} url={iconField} withname={true}/>
            }
        </Box>
    );
}

export default CellLegalEntityFlat
import React, {useContext, useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/car';
const wizardName = 'Contract dissociation';
const wizardObject = 'Contract';
const wizardAction = 'contractDissociation';

const ContractDissociation = (params) => {
    const { t } = useTranslation();
    const { userCapabilities, existingModules } = useContext(AppContext);


    // DATA PROVIDER
    const {doAction, item,  errorMessage, errors, saving, success} = useDataProvider(dataProviderName, params.multipart);

    let dsvs = params.defaultSelectedValues;
    if (typeof dsvs == 'string') {
        dsvs = dsvs.split(',').map((dsv) => { return {'_id': dsv}});
    } else if (typeof dsvs == 'undefined') {
        dsvs = [];
    } else{
        dsvs = [dsvs];
    }

    const modelName = existingModules.modelName
    const detailConfig = {
        "tabs": [
            {
                "name": "dissociation",
                "fieldsets": [
                    {
                        "name": "dissociation",
                        "fields": {
                            "contract_assigned": {
                                "type": "sectiontitle",
                                "className": "col-12"
                            },
                            "dissociated": {
                                "type": "contractlist",
                                "defaultSelectedValues": dsvs,
                                "enableSelection": true,
                                "disableMultipleRowSelection": true,
                                "dataProviderName": "cars/contract",
                                "className": "col-12",
                                "required": "true",
                                "filters": {
                                    "items": [
                                        {
                                            "field": "_id", //_id, perché id è undefined?
                                            "operator": "isAnyOf",
                                            "value": item?.data?.contracts?.map((contract) => contract._id).flat(Infinity) //_id, perché id è undefined?
                                        }
                                    ]
                                }
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }

    const [editing, setEditing] = useState(false)


    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success, editing, params, item])



    const handleSave = async (data) => {
        doAction && await doAction({ action: wizardAction, model: dataProviderName, data: data });
    }

    return <>
        <DetailComponent
            values={{ "id": item?.data?._id, "dissociated": dsvs?.map((dsv) => dsv._id) }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            onEditing={setEditing}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.'+wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Remove {{wizardObject}} from {{plate}}', {  wizardObject:wizardObject, plate: item.data.plate })}
                />
            }
            {...params}
        />
    </>
}

export default ContractDissociation;



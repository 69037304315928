import React, { useState, useEffect, useContext } from 'react';
import { InfoHeader, Detail as DetailComponent, Confirm, useDrawer, SplitButton } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import {useLoadOne} from "../../../../mtska-frontend-data-provider/hooks/useLoadOne.hooks";

const dataProviderName = 'persons/person';
const drawerRouteBase = 'persons';

const Detail = (params) => {
    const { t } = useTranslation();
    const { activate, close: closeDrawer } = useDrawer();

    const { userCapabilities, existingModules, appConfiguration } = useContext(AppContext);


    // DATA PROVIDER
    const { item, save, loadOne, destroy, errorMessage, errors, saving, success, reloadCounter } = useDataProvider(dataProviderName, params.multipart);

  
    const [assignedServices, setAssignedServices] = useState([])

    const {modelName, detailConfig} = existingModules[dataProviderName]

    // Ricerco i servizi collegati per poi passare il filtro al bottone di aggiunta costi
    const loadAssignedServices = (item) => {
        let services = [];

        const direct = item?.data?.employment?.services?.map((service) => service._id).flat(Infinity);
        const car = item?.data?.employment?.pools?.map((pool) => pool.car?.services?.map((service) => service._id)).flat(Infinity);
        const contract = item?.data?.employment?.pools?.map((pool) => pool.car?.contract?.services?.map((service) => service._id)).flat(Infinity);

        if (typeof direct != 'undefined') services.push(...direct);
        if (typeof car != 'undefined') services.push(...car);
        if (typeof contract != 'undefined') services.push(...contract);

        setAssignedServices(services.filter(item => item !== undefined));
    };

    useLoadOne({loadOne, params, reloadCounter, onSuccess: loadAssignedServices})


    const [editing, setEditing] = useState(false)

    const handleSave = (data) => {
        save({...data, filters: params.filters}).then((data) => params.handleClose(params.uid, data.item.data)).catch(() => console.error('catch handleSave'));
    }

    const [confirmDelete, setConfirmDelete] = useState();
    const handleDelete = (id) => {
        setConfirmDelete(
            <Confirm
                oklabel={t("Delete")}
                okcallable={() => {
                    destroy && destroy(id);
                    closeDrawer();
                }}
                kolabel={t("Cancel")}
                kocallable={() => { setConfirmDelete(null) }}
                title={t("Item elimination")}>
                {t("Are you sure to remove this element?")}
            </Confirm>
        );
    }

    return (
        <>
            <DetailComponent
                values={{ ...item?.data }}
                modelName={modelName}
                detailConfig={detailConfig}
                onEditing={setEditing}
                save={handleSave}
                saving={saving}
                errors={errors}
                errorMessage={errorMessage}
                canSave={userCapabilities && userCapabilities[dataProviderName+'.edit']}
                title={item?.data?.name == ' ' ? t('New Person') : item?.data?.name}
                header={
                    <InfoHeader
                        view={appConfiguration && appConfiguration.infoheaders?.person}
                        item={item?.data}
                    />
                }
                rightButtons={[
                    <SplitButton 
                        actions={[
                            {
                                disabled: userCapabilities && !userCapabilities['costs.create'],
                                icon: "faMoneyBill",
                                label: t('Add Cost'),
                                onClick: () => {
                                    activate('costs/create', {
                                        detailStack: params.detailStack ?? {},
                                        employment: {...item?.data?.employment, person:{_id:item?.data?._id, firstname:item?.data?.firstname, lastname:item?.data?.lastname}},
                                        legalEntity: item?.data?.legalEntity,
                                        detailConfigOverride: {
                                            "tabs": [
                                                {
                                                    "fieldsets": [
                                                        {
                                                            "fields": {
                                                                "service": {
                                                                    "type": "servicelist",
                                                                    "className": "col-12",
                                                                    "hideTableToolbar": true,
                                                                    "filters": {
                                                                        "items": [
                                                                            {
                                                                                "field": "_id",
                                                                                "operator": "isAnyOf",
                                                                                "value": assignedServices
                                                                            }
                                                                        ]
                                                                    },
                                                                },
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]

                                        }
                                    },
                                    () => { loadOne(params.id); });
                                }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities[dataProviderName + '.employmentassociation'],
                                icon: "faSuitcase",
                                label: t('Assign an Employment'),
                                onClick: () => { activate(drawerRouteBase + '/employmentAssociation', { detailStack: params.detailStack ?? {} }); }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities[dataProviderName + '.employmentdissociation'],
                                icon: "faSuitcase",
                                label: t('Terminate an Employment'),
                                onClick: () => { activate(drawerRouteBase + '/employmentDissociation', { detailStack: params.detailStack ?? {} }); }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities[dataProviderName + '.serviceassociation'],
                                icon: "faCreditCard",
                                label: t('Assign a Service'),
                                onClick: () => { activate(drawerRouteBase + '/serviceAssociation', { detailStack: params.detailStack ?? {} }); }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities[dataProviderName + '.servicedissociation'],
                                icon: "faCreditCard",
                                label: t('Remove a Service'),
                                onClick: () => { activate(drawerRouteBase + '/serviceDissociation', { detailStack: params.detailStack ?? {} }); }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities[dataProviderName + '.carassociation'],
                                icon: "faCar",
                                label: t('Assign a Car'),
                                onClick: () => { activate(drawerRouteBase + '/carAssociation', { detailStack: params.detailStack ?? {} }); }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities[dataProviderName + '.poolassociation'],
                                icon: "faCars",
                                label: t('Assign a Pool'),
                                onClick: () => { activate(drawerRouteBase + '/poolAssociation', { detailStack: params.detailStack ?? {} }); }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities[dataProviderName + '.cardissociation'],
                                icon: "faCar",
                                label: t('Remove a Car'),
                                onClick: () => { activate(drawerRouteBase + '/carDissociation', { detailStack: params.detailStack ?? {} }); }
                            },
                            {
                                disabled: userCapabilities && !userCapabilities[dataProviderName + '.delete'],
                                icon: "faTrash",
                                label: t('Delete'),
                                onClick: () => { handleDelete(item.data.id); }
                            },
                        ]}
                    />
                ]}
                {...params}
            />
            {confirmDelete}
        </>
    )
}

export default Detail;



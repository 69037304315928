import React from 'react';
import { ListItem } from '@mui/joy';

const CellCountableMore = ({ length, limit }) => {
    return (
        length - limit > 0 && (
            <ListItem className={'cell-list-item-count cell-cars-list-item-count'}>
                <span>+{length - limit}</span>
            </ListItem>
        )
    );

}

export default CellCountableMore;
export const styles = {
    containerBoxSx: {
        alignItems: 'center',
        display: 'grid',
        gridTemplateColumns: '1fr 2fr 2fr',
        padding: 2
    },
    currentPageBoxSx: {
        alignItems: 'center',
        display: 'flex',
        gap: '10px',
        justifyContent: 'right'
    },
    pageSizeBoxSx: {
        alignItems: 'center',
        display: 'flex',
        gap: '10px',
        justifySelf: 'right'
    }
}

import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';
import { Tag } from '../Snippet';
import { useFormatter } from '../../utils';
import { useTranslation } from 'react-i18next'

const RelationshipEmployments = ({ k, v, editing, handleItemDetail, handleItemRemove }) => {
    const { t, i18n } = useTranslation();
    const { formatDate } = useFormatter();

    const tagcolors = {
        active: "success",
        past: "warning",
        future: "secondary",
        missing_timeframe: "danger"
    }

    return (
        <ListItem k={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content"}>
                <Box className="role">
                    <span>{v?.role}</span>
                </Box>
                <Box className="coupled">
                    <Box className="label">{t('Legal Entity')}</Box>
                    <Box className="value">
                        <span>{v?.legalEntity?.name}</span>
                    </Box>
                </Box>
                <Box className="coupled">
                    <Box className="label">{t('hired_at')}</Box>
                    <Box className="value">
                        <span>{formatDate(v?.hired_at)}</span>
                    </Box>
                </Box>
                <Box className="coupled">
                    <Box className="label">{t('terminated_at')}</Box>
                    <Box className="value">
                        <span>{formatDate(v?.terminated_at)}</span>
                    </Box>
                </Box>
                <Box className={"status"}>
                    <Tag color={(tagcolors[v?.status ? v?.status : 'missing_timeframe'])} size="small" label={t(v?.status ? v?.status : 'missing_timeframe')} />
                </Box>
            </ListItemContent>

            {editing && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}

export default RelationshipEmployments




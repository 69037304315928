import React, { useEffect, useContext, useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { Footer, Header, Aside, Main } from '../..';
import { AppContext } from '../../../contexts';
import { Box } from '@mui/joy';
import { ModuleMenu } from '../../../../mtska-frontend-app-component';
import { useStyleVariableInjector } from 'src/mtska-frontend-app-scaffolding/utils/StyleVariableInjector';

const Full = () => {

    const { setCurrentModuleName, setCurrentModuleScope, setCurrentModuleNavigationData, appConfiguration } = useContext(AppContext);
    const { injectColorsToDocumentNode } = useStyleVariableInjector();

    const [isOpen, setIsOpen] = useState();
    const location = useLocation();
    
    useEffect(() => {
        for (const [key, module] of Object.entries(appConfiguration.modules.active)) {
            if (!module.hasOwnProperty('menuItemScope') || !module.hasOwnProperty('menuItemName')) continue;
            
            const moduleMenuGroup = appConfiguration.navigation[module.menuItemScope];
            const moduleMenuItem = moduleMenuGroup[module.menuItemName];

            if (moduleMenuItem && moduleMenuItem.href === location.pathname) { 
                setCurrentModuleName(moduleMenuItem.title);
                setCurrentModuleScope(moduleMenuItem.scope);
            }
            if (moduleMenuGroup && moduleMenuItem.href === location.pathname) { 
                setCurrentModuleNavigationData(moduleMenuGroup);
            }
        }
    }, [location]);

    useEffect(() => {
        setIsOpen((sessionStorage.getItem('prefersAsideOpen') === 'true') ?? true);
    }, [])

    const handleIsOpen = () => {
        setIsOpen(!isOpen);
    }

    injectColorsToDocumentNode(appConfiguration.colors);

    return (
        <Box className={(isOpen) ? 'app open' : 'app closed'}>
            <div className="superheader"></div>
            <Aside onOpenLayout={handleIsOpen}/>
            <Header />
            <ModuleMenu />
            <Main>
                <Outlet />
            </Main>

            <Footer />
        </Box>
    );
};

export default Full;

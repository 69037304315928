import { Create, Default, Detail, List } from "./views";



const Module = {
    name: "documents",
    defaultRoutePath: "/documents/document",
    menuItemScope: "documents",
    menuItemName: "documents",

    routes: [
        {
            path: "/documents/document",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Default,
                },
                {
                    path: "list",
                    Component: List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: List,
        },
        {
            path: "detail",
            Component: Detail,
        },
        {
            path: "create",
            Component: Create,
        }
        
    ],
   
}

export default Module;

import React, { useState, useEffect } from 'react';
import { AppContextProvider } from '../contexts';
import { ContextualizedApp } from '../app';
import { CircularProgress } from "@mui/joy";
import { Backdrop } from "@mui/material";
import { useDataProvider } from '../../mtska-frontend-data-provider';
import * as Modules from '../../mtska-frontend-modules';
import '../../mtska-frontend-app-component/styles/components.scss';

const App = () => {

    const { loadOne } = useDataProvider('auth/token');
    const { item: config, loadOne: loadConfig } = useDataProvider('configs/config');
    const { loadAll: loadSettings, save: saveSetting } = useDataProvider('settings/setting');
    const { item: appconfig, loadOne: loadAppConfig } = useDataProvider('applications/app');
    const { item: moduleconfig, loadOne: loadModuleConfig } = useDataProvider('applications/module');

    const [appConfiguration, setAppConfiguration] = useState()
    const [userCapabilities, setUserCapabilities] = useState()
    const [existingModules, setExistingModules] = useState()
    const [settings, setSettings] = useState()

    const t = sessionStorage.getItem('token')

    useEffect(() => {

        loadAppConfig('670e3907863d1b0bfa9706b9').then((response) => {
            if (response && response.item && response.item.data) {
                response.item.data.modules.active = response.item.data.modules.active.map((module) => Modules[module]).filter((item) => { return item !== undefined })
                setAppConfiguration(response.item.data);
            }
        });

        loadModuleConfig('0').then((response) => {
            if (response && response.item && response.item.data) {
                setExistingModules(response.item.data);
            }
        });

        if (t != null) {
            loadOne(sessionStorage.getItem('token'));
            loadConfig(sessionStorage.getItem('token')).then((response) => {
                if (response && response.item && response.item.data) {
                    setUserCapabilities(response.item.data.capabilities);
                }
            });

            !settings && loadSettings(sessionStorage.getItem('token')).then((res) => {
                const { items = {} } = res
                const { data = {} } = items
                setSettings(data)
            })
        } else {
            setUserCapabilities([]);
        }
    }, [t])


    const concreteconfig = {
        appConfiguration,
        existingModules,
        saveSetting,
        settings,
        setSettings,
        userCapabilities,
        setUserCapabilities
    }

    return (
        (appConfiguration && userCapabilities && existingModules) ? (
            <AppContextProvider concreteconfig={concreteconfig}>
                <ContextualizedApp />
            </AppContextProvider>
        ) : (
            <Backdrop open={true}>
                <CircularProgress />
            </Backdrop>
        )
    )
}

export default App;
import React from "react";
import { AppContext } from '../../contexts';
import { Text, Anchor } from '../../../mtska-frontend-app-component';

const Footer = () => {
    const { appConfiguration } = React.useContext(AppContext);

    return (
        <footer>
            <div className="copyright"><Text>{appConfiguration.app.copyright}</Text></div>
            <div className="privacy"><Anchor component="a" href={appConfiguration.privacy.url} target="_blank">{appConfiguration.privacy.label}</Anchor></div>
        </footer>
    );
}

export default Footer;
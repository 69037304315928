import React from 'react';
import { Icon } from '../../assets';
import { LegalEntityAvatar } from '../Snippet';
import { Box, List, ListItem } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { CellCountableMore } from './';

const CellContract = ({ type, value, limit = 1 }) => {
    if (!Array.isArray(value) && value) {
        value = [value];
    } else if (value == null) {
        value = [];
    }
    const length = Object.keys(value).length;

    const { t } = useTranslation();


    return (
        <Box className={"list-element-cell cell-contract cell-contract-" + type}>
            {length > 0 ? (
                <List className="cell-contract-list">
                    {value.map((item, k) => {
                        if (k >= limit || item == null) return;
                        return (
                            <ListItem key={k} className={'cell-generics-list-item'}>
                                <LegalEntityAvatar name={item.renter?.name} detail={item.identifier} url={item.renter?.logo?.url} withname={true} />
                            </ListItem>
                        );
                    })}
                    <CellCountableMore limit={limit} length={length} />
                </List>
            ) : ''
            // ) : (
            //     <List className="cell-contract-list">
            //         <ListItem key={0} className={'cell-generics-list-item'}>
            //             <span className={"status status-warning"}>
            //                 <Icon icon="faWarning" />
            //                 {t('No contract')}
            //             </span>
            //         </ListItem>
            //     </List>
            // )
            }
        </Box>
    )

}

export default CellContract;
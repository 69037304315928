import React from "react";
import { Box } from "@mui/joy";
import { useTranslation } from 'react-i18next';
import { Tag } from ".";

const VehicleAvatar = ({ car, size = "default", withname = false, detail = false, error = false }) => {

    const { t } = useTranslation();

    return (
        <Box className={"vehicle-avatar-wrapper vehicle-avatar-withname-" + withname}>
            <Box className={"vehicle-avatar vehicle-avatar-" + size}>
                <img src={'/images/car.png'} className="default" />
            </Box>
            {(withname && !error) && (
                <Box className={"vehicle-name" + (detail && " hasdetail")}>
                    {
                        car?.plate ? (
                            <span className="fullname" title={car?.plate}>{car?.plate}</span>
                        ) : (
                            <Tag color={"warning"} title={t("Missing")}>{t('No car assigned')}</Tag>
                        )
                    }
                    
                    {
                        detail && (
                            <span className="detail">{(detail)}</span>
                        )
                    }
                </Box>
            )}
        </Box>
    )
}

export default VehicleAvatar;
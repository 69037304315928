import createConstants from './constants';


const constants = createConstants();

const initialStatus = {
    global: {
        items: {},
        item: null,
    },
    components: {}
};


export function reducer(state = {}, action) {

    if (typeof action.dataProviderName === 'undefined') {
        return state;
    }

    const newState = { ...dataProviderReducer(state, action) }

    return newState;

}

export function dataProviderReducer(state, action) {

    const { reloadCounter = 0 } = state
    const tmp = state[action.dataProviderName];
    const dataProviderState = (!tmp || tmp === 'undefined') ? initialStatus : tmp;

    switch (action.type) {

        case constants.ACTION_REQUEST:
            return {
                ...state,
                [action.dataProviderName]: {
                    ...dataProviderState,
                    components: {
                        ...dataProviderState.components,
                        [action.uid]: {
                            ...dataProviderState.components[action.uid],
                            item: null,
                            errors: {},
                            errorMessage: null,
                            success: false,
                            saving: true,
                        }
                    }
                }
            }

        case constants.ACTION_SUCCESS:
            return {
                ...state,
                reloadCounter: reloadCounter + 1,
                [action.dataProviderName]: {
                    ...dataProviderState,
                    global: {
                        ...dataProviderState.global,
                        items: {
                            ...dataProviderState.global.items,
                            [action.item.data.id]: { ...dataProviderState.global.items[action.item.data.id], ...action.item.data },
                        }

                    },
                    components: {
                        ...dataProviderState.components,
                        [action.uid]: {
                            ...dataProviderState.components[action.uid],
                            success: true,
                        }
                    },
                }
            }

        case constants.ACTION_FAILURE:
            return {
                ...state,
                [action.dataProviderName]: {
                    ...dataProviderState,
                    components: {
                        ...dataProviderState.components,
                        [action.uid]: {
                            ...dataProviderState.components[action.uid],
                            success: false,
                            saving: false,
                            errors: action.errors,
                            errorMessage: action.message,
                        }
                    }
                }
            }

        case constants.SAVE_REQUEST:
            return {
                ...state,
                [action.dataProviderName]: {
                    ...dataProviderState,
                    components: {
                        ...dataProviderState.components,
                        [action.uid]: {
                            ...dataProviderState.components[action.uid],
                            item: null,
                            errors: {},
                            errorMessage: null,
                            success: false,
                            saving: true,
                        }
                    }
                }
            }

        case constants.SAVE_SUCCESS:
            return {
                ...state,
                reloadCounter: (action.canReloadCounter !== false ? reloadCounter + 1 : reloadCounter),
                [action.dataProviderName]: {
                    ...dataProviderState,
                    global: {
                        ...dataProviderState.global,
                        item: action.item,
                        items: { ...dataProviderState.global.items, ...setGlobalItem(action.item?.data) },
                    },
                    components: {
                        ...dataProviderState.components,
                        [action.uid]: {
                            ...dataProviderState.components[action.uid],
                            item: action.item?.data?._id ? action.item.data._id : action.item,
                            loading: false,
                            saving: false
                        }
                    },
                }
            }

        case constants.SAVE_FAILURE:
            return {
                ...state,
                [action.dataProviderName]: {
                    ...dataProviderState,
                    components: {
                        ...dataProviderState.components,
                        [action.uid]: {
                            ...dataProviderState.components[action.uid],
                            success: false,
                            saving: false,
                            errors: action.errors,
                            errorMessage: action.message,
                        }
                    }
                },
            }

        case constants.DELETE_REQUEST:
            return {
                ...state,
                [action.dataProviderName]: {
                    ...dataProviderState,
                    components: {
                        ...dataProviderState.components,
                        [action.uid]: {
                            loading: true,
                            saving: false,
                        }
                    }
                }
            }

        case constants.DELETE_SUCCESS:
            return {
                ...state,
                reloadCounter: reloadCounter + 1,
                [action.dataProviderName]: {
                    ...dataProviderState,
                    components: {
                        ...dataProviderState.components,
                        [action.uid]: {
                            loading: false,
                            saving: false
                        }
                    }
                }
            }

        case constants.DELETE_FAILURE:
            return {
                ...state,
                [action.dataProviderName]: {
                    ...dataProviderState,
                    components: {
                        ...dataProviderState.components,
                        [action.uid]: {
                            loading: false,
                            saving: false
                        }
                    }
                }
            }

        case constants.GETALL_REQUEST:
            return {
                ...state,
                [action.dataProviderName]: {
                    ...dataProviderState,
                    components: {
                        ...dataProviderState.components,
                        [action.uid]: {
                            items: [],
                            loading: true,
                            saving: false
                        }
                    }
                }
            }

        case constants.GETALL_SUCCESS: {
            return {
                ...state,
                [action.dataProviderName]: {
                    ...dataProviderState,
                    global: {
                        ...dataProviderState.global,
                        items: { ...dataProviderState.global.items, ...setGlobalItems(action.items.data) },
                    },
                    components: {
                        ...dataProviderState.components,
                        [action.uid]: {
                            ...dataProviderState.components[action.uid],
                            items: setItems(action.items.data),
                            total: action.items.meta.total,
                            loading: false,
                            saving: false,
                        }
                    }
                }
            }
        }

        case constants.GETALL_FAILURE:
            return {
                ...state,
                [action.dataProviderName]: {
                    ...dataProviderState,
                    components: {
                        ...dataProviderState.components,
                        [action.uid]: {
                            error: action.error,
                            loading: false,
                            saving: false
                        }
                    }
                }
            }

        case constants.GETONE_REQUEST:
            return {
                ...state,
                [action.dataProviderName]: {
                    ...dataProviderState,
                    components: {
                        ...dataProviderState.components,
                        [action.uid]: {
                            item: action.id,
                            errors: {},
                            errorMessage: null,
                            saving: false,
                            loading: true
                        }
                    }
                }
            }

        case constants.GETONE_SUCCESS:
            return {
                ...state,
                [action.dataProviderName]: {
                    ...dataProviderState,
                    global: {
                        ...dataProviderState.global,
                        item: action.item,
                        items: { ...dataProviderState.global.items, ...setGlobalItem(action.item?.data) },
                    },
                    components: {
                        ...dataProviderState.components,
                        [action.uid]: {
                            ...dataProviderState.components[action.uid],
                            item: action.item?.data?._id ? action.item.data._id : action.item,
                            loading: false,
                            saving: false
                        }
                    }
                }
            }

        case constants.GETONE_FAILURE:
            return {
                ...state,
                [action.dataProviderName]: {
                    ...dataProviderState,
                    components: {
                        ...dataProviderState.components,
                        [action.uid]: {
                            error: action.error,
                            loading: false,
                            saving: false
                        }
                    }
                }
            }

        case constants.RESET_REQUEST:
            return {
                ...state,
                [action.dataProviderName]: {
                    ...dataProviderState,
                    components: {
                        ...dataProviderState.components,
                        [action.uid]: {
                            items: [],
                            loading: true,
                            saving: false,
                        }
                    }
                }
            }

        case constants.RESET_SUCCESS:
            return {
                ...state,
                [action.dataProviderName]: initialStatus
            }

        case constants.RESET_FAILURE:
            return {
                ...state,
                [action.dataProviderName]: {
                    ...dataProviderState,
                    components: {
                        ...dataProviderState.components,
                        [action.uid]: {
                            error: action.error,
                            loading: false,
                            saving: false
                        }
                    }
                }
            }

        case constants.DOWNLOAD_REQUEST:
            return {
                ...state,
                [action.dataProviderName]: {
                    ...dataProviderState,
                    components: {
                        ...dataProviderState.components,
                        [action.uid]: {
                            ...dataProviderState.components[action.uid],
                            downloading: true,
                        }
                    }
                }
            }

        case constants.DOWNLOAD_SUCCESS:
            return {
                ...state,
                [action.dataProviderName]: {
                    ...dataProviderState,
                    components: {
                        ...dataProviderState.components,
                        [action.uid]: {
                            ...dataProviderState.components[action.uid],
                            downloading: false,
                        }
                    }
                }
            }

        case constants.DOWNLOAD_FAILURE:
            return {
                ...state,
                [action.dataProviderName]: {
                    ...dataProviderState,
                    components: {
                        ...dataProviderState.components,
                        [action.uid]: {
                            ...dataProviderState.components[action.uid],
                            downloading: false,
                        }
                    }
                }
            }

        default:
            return {
                ...state,
                [action.dataProviderName]: dataProviderState
            }
    }
}

const setItems = (items) => {
    if (Array.isArray(items)) {
        return items.map(item => item._id ? item._id : item)
    } else {
        return items
    }
}

const setGlobalItems = (items) => {
    if (Array.isArray(items)) {
        let globals = {}
        items.forEach(item => globals[item._id] = item)
        return globals
    } else {
        return items
    }
}

const setGlobalItem = (item) => {
    return item && item._id ? { [item._id]: item } : item
}

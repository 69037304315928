import React, { useContext } from 'react';
import { List, ListItem } from '@mui/joy';
import { RouterAnchor as Anchor } from "../RouterAnchor"
import { AppContext } from '../../../mtska-frontend-app-scaffolding';

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon } from 'src/mtska-frontend-app-component/assets';


const ModuleMenu = ({ activities, wizards }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const { currentModuleNavigationData, userCapabilities } = useContext(AppContext);

    return (
        <nav className="module-menu">
            <List className="module-menu-portion module-menu-portion-activities">
                {currentModuleNavigationData && Object.entries(currentModuleNavigationData).map(([k, moduleMenuItem]) => {
                    return (((moduleMenuItem && userCapabilities) && userCapabilities[moduleMenuItem.capability] && moduleMenuItem.moduleMenuVisibility) &&
                        <ListItem key={k} className={(moduleMenuItem.href == location.pathname) ? "module-menu-item module-menu-item-selected" : "module-menu-item"}>
                            <Anchor underline="none" className="label" href={moduleMenuItem.href}>
                                {
                                    moduleMenuItem.icon && <Icon icon={moduleMenuItem.icon} className="icon" />
                                }
                                {t(moduleMenuItem.title)}
                            </Anchor>
                        </ListItem>
                    );
                })}
            </List>
        </nav>
    )
};

export default ModuleMenu;

import React from "react"

import { FormControl, FormLabel } from "@mui/joy";
import { Controller } from "react-hook-form";
import { List } from "src/mtska-frontend-modules/modules/TraceKM/views";
import { useTranslation } from 'react-i18next';
import { useListFiltersAdapter } from "src/mtska-frontend-app-component/utils";

const InputListTrace = ({
    control,
    editing,
    name,
    label,
    readonly,
    className = "",
    dataProviderName,
    defaultSelectedValues,
    enableSelection,
    disableMultipleRowSelection,
    hideTableToolbar = false,
    filters,
    relation
}) => {

    const { adaptRelationshipFilters } = useListFiltersAdapter();
    const { t } = useTranslation();
    if (!editing) readonly = true;

    let generalClass = "form-element-control";
    generalClass += (editing) ? " editing" : " reading";
    generalClass += (readonly) ? " readonly" : "";

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value, name },
                fieldState: { error, invalid },
                formState,
            }) => {

                const rowSelectionExternalListener = (selectionModel) => {
                    onChange(selectionModel);
                }

                filters = adaptRelationshipFilters(filters, relation, formState);

                return (
                    <div className={("form-element list " + className).trim()}>
                        <FormControl error={invalid} className={(error) ? generalClass + " form-element-error" : generalClass}>
                            <FormLabel className="form-element-label" htmlFor={name}>{t(label)}</FormLabel>
                            <List
                                leftButtons={[]}
                                rightButtons={[]}
                                dataProviderName={dataProviderName}
                                title={label}
                                rowSelectionExternalListener={rowSelectionExternalListener}

                                defaultSelectedValues={defaultSelectedValues}
                                enableSelection={enableSelection}
                                disableMultipleRowSelection={disableMultipleRowSelection}

                                className={className}
                                hideTableToolbar={hideTableToolbar}
                                isEmbedded={true}
                                filters={filters}
                            />
                        </FormControl>
                    </div>
                )
            }}
        />
    );
};


export default InputListTrace;

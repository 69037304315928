import React from "react";
import { Box } from "@mui/joy";

const VehicleAvatar = ({ size = "default", url = null, withname = false }) => {

    return (
        <Box className={"vehicle-avatar-wrapper vehicle-avatar-withname-" + withname}>
            <Box className={"vehicle-avatar vehicle-avatar-" + size}>
                {url && (
                    <img src={url} />
                )}
                {!url && (

                    <img src={'/images/car.png'} className="default" />

                )}
            </Box>
        </Box>
    )
}

export default VehicleAvatar;
import React, { useContext } from 'react';
import { Text } from "../Typography";
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const ActiveAppName = ({ }) => {
    const { appConfiguration } = useContext(AppContext);

    return (
        <div className="appname">
            <div className='logo' dangerouslySetInnerHTML={{ __html: appConfiguration.app.logoSvg }} />
            
            <div className="name">
                <Text level="h1">{appConfiguration.app.shortName}</Text>
            </div>
        </div>
    );
}

export default ActiveAppName;



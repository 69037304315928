import React from "react"
import InfoHeaderPerson from "./InfoHeaderPerson";
import InfoHeaderDriver from "./InfoHeaderDriver";
import InfoHeaderLegalEntity from "./InfoHeaderLegalEntity";
import InfoHeaderVehicle from "./InfoHeaderVehicle";
import InfoHeaderWizard from "./InfoHeaderWizard";

const InfoHeader = ({ view, item, control, title, subtitle }) => {

    if (view == 'person') {
        return <InfoHeaderPerson
            view={view}
            item={item}
            control={control}
        />
    }

    if (view == 'driver') {
        return <InfoHeaderDriver
            view={view}
            item={item}
            control={control}
        />
    }

    if (view == 'legalEntity') {
        return <InfoHeaderLegalEntity
            view={view}
            item={item}
            control={control}
        />
    }

    if (view == 'vehicle') {
        return <InfoHeaderVehicle
            view={view}
            item={item}
            control={control}
        />
    }

    if (view == 'wizard') {
        return <InfoHeaderWizard
            view={view}
            title={title}
            subtitle={subtitle}
        />
    }
};

export default InfoHeader

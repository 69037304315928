import React from 'react';
import { Box, List, ListItem } from '@mui/joy';
import { CellCountableMore } from './';

const CellGenericList = ({ type, value, field, limit = 1 }) => {
    if (!Array.isArray(value) && value) {
        value = [value];
    } else if (value == null) {
        value = [];
    }
    const length = (value) ? Object.keys(value).length : 0;

    return (
        <Box className={"list-element-cell cell-generics cell-generics-"+type}>
            {length > 0 && (
                <List className="cell-generics-list">
                    {value.map((item, k) => {
                        if (k >= limit || item == null) return;
                        return (
                            <ListItem key={k} className={'cell-generics-list-item'}>
                                <span className="name" title={item[field]}>{item[field]}</span>
                            </ListItem>
                        );
                    })}
                    <CellCountableMore limit={limit} length={length} />
                </List>
            )}
        </Box>
    )
    
}

export default CellGenericList;
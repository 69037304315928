import { useEffect, useMemo } from 'react'
import { getEndAt, getTotalLeaseFee, getEmissionsForFringeBenefitByProvider, getEmissionsForFringeBenefitByManual, getEmissionsForFringeBenefitBySetup, getFringeBenefitFieldsByProvider, getFringeBenefitFieldsByCar, getFringeBenefitFieldsByEmission } from './detail.utils'

export const useFieldWatcher = ({ setValue, watchedFields }) => {

    const {
        advanceFee = 0,
        contractDuration,
        deposit = 0,
        financialFee = 0,
        fuelFee = 0,
        fuelIncluded,
        insuranceFee = 0,
        insuranceIncluded,
        serviceFee = 0,
        start_at,
        taxFee = 0,
        taxIncluded,

        emissionsProvider,
        emissionsManual,
        setup,

        fbProvider,
        emissionsFinal,
        fbFringeBenefitCar,
        fbModelSetupProvider,
        fbMonthly,
        fbAnnual,
        fbCurrency,
    } = watchedFields

    const endAtWatchedFields = { contractDuration, start_at }
    const totalLeaseFeeWatchedFields = {
        advanceFee,
        deposit,
        financialFee,
        fuelFee,
        fuelIncluded,
        insuranceFee,
        insuranceIncluded,
        serviceFee,
        taxFee,
        taxIncluded
    }
    const emissionsForFringeBenefitFieldsByProvider = { emissionsProvider }
    const emissionsForFringeBenefitFieldsByManual = { emissionsManual }
    const emissionsForFringeBenefitFieldsBySetup = { setup }
    const fringeBenefitFieldsByProvider = { fbProvider }
    const fringeBenefitFieldsByCar = { fbFringeBenefitCar }
    const fringeBenefitFieldsByEmissions = { emissionsFinal }

    useEndAt({ setValue, watchedFields: endAtWatchedFields })
    useTotalLeaseFee({ setValue, watchedFields: totalLeaseFeeWatchedFields })

    useEmissionsForFringeBenefitByProvider({ setValue, watchedFields: emissionsForFringeBenefitFieldsByProvider, emissionsManual, setup })
    useEmissionsForFringeBenefitByManual({ setValue, watchedFields: emissionsForFringeBenefitFieldsByManual, emissionsProvider, setup })
    useEmissionsForFringeBenefitBySetup({ setValue, watchedFields: emissionsForFringeBenefitFieldsBySetup, emissionsProvider, emissionsManual })

    useFringeBenefitFieldsByProvider({ setValue, watchedFields: fringeBenefitFieldsByProvider, emissionsFinal, fbFringeBenefitCar, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency })
    useFringeBenefitFieldsByCar({ setValue, watchedFields: fringeBenefitFieldsByCar, emissionsFinal, fbProvider, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency })
    useFringeBenefitFieldsByEmission({ setValue, watchedFields: fringeBenefitFieldsByEmissions, fbFringeBenefitCar, fbProvider, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency })
}

const useEndAt = ({ setValue, watchedFields }) => {

    useEffect(() => {
        const endAt = getEndAt(watchedFields)
        setValue('end_at', endAt)
    }, [...Object.values(watchedFields)])

}

const useTotalLeaseFee = ({ setValue, watchedFields }) => {

    useEffect(() => {
        const totalLeaseFee = getTotalLeaseFee(watchedFields)
        setValue('totalLeaseFee', totalLeaseFee)
    }, [...Object.values(watchedFields)])

}

const useEmissionsForFringeBenefitByProvider = ({ setValue, watchedFields, emissionsManual, setup }) => {

    useEffect(() => {
        const emissionsFinal = getEmissionsForFringeBenefitByProvider(watchedFields, emissionsManual, setup);
        setValue('emissionsFinal', emissionsFinal)
    }, [...Object.values(watchedFields)])
}

const useEmissionsForFringeBenefitByManual = ({ setValue, watchedFields, emissionsProvider, setup }) => {

    useEffect(() => {
        const emissionsFinal = getEmissionsForFringeBenefitByManual(watchedFields, emissionsProvider, setup);
        setValue('emissionsFinal', emissionsFinal)
    }, [...Object.values(watchedFields)])
}

const useEmissionsForFringeBenefitBySetup = ({ setValue, watchedFields, emissionsProvider, emissionsManual }) => {
    const memoizedWatchedFields = useMemo(() => watchedFields, [JSON.stringify(watchedFields)]);

    useEffect(() => {
        const emissionsFinal = getEmissionsForFringeBenefitBySetup(watchedFields, emissionsProvider, emissionsManual);
        setValue('emissionsFinal', emissionsFinal)
    }, [memoizedWatchedFields])
}

const useFringeBenefitFieldsByProvider = ({ setValue, watchedFields, emissionsFinal, fbFringeBenefitCar, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency}) => {

    useEffect(() => {
        const fringeBenefitFields = getFringeBenefitFieldsByProvider(watchedFields, emissionsFinal, fbFringeBenefitCar, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency);

        if (fringeBenefitFields ==  undefined) return;

        setValue('fbAnnualFinal', fringeBenefitFields.fbAnnual);
        setValue('fbMonthlyFinal', fringeBenefitFields.fbMonthly);
        setValue('fbModelSetupProviderFinal', fringeBenefitFields.fbModelSetupProvider);
        setValue('fbCurrencyFinal', fringeBenefitFields.fbCurrency);

    }, [...Object.values(watchedFields)])

}

const useFringeBenefitFieldsByCar = ({ setValue, watchedFields, emissionsFinal, fbProvider, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency}) => {
    const memoizedWatchedFields = useMemo(() => watchedFields, [JSON.stringify(watchedFields)]);

    useEffect(() => {
        const fringeBenefitFields = getFringeBenefitFieldsByCar(watchedFields, emissionsFinal, fbProvider, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency);

        if (fringeBenefitFields ==  undefined) return;

        setValue('fbAnnualFinal', fringeBenefitFields.fbAnnual);
        setValue('fbMonthlyFinal', fringeBenefitFields.fbMonthly);
        setValue('fbModelSetupProviderFinal', fringeBenefitFields.fbModelSetupProvider);
        setValue('fbCurrencyFinal', fringeBenefitFields.fbCurrency);

    }, [memoizedWatchedFields])

}


const useFringeBenefitFieldsByEmission = ({ setValue, watchedFields, fbFringeBenefitCar, fbProvider, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency}) => {
    const memoizedWatchedFields = useMemo(() => watchedFields, [JSON.stringify(watchedFields)]);

    useEffect(() => {
        const fringeBenefitFields = getFringeBenefitFieldsByEmission(watchedFields, fbFringeBenefitCar, fbProvider, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency);

        if (fringeBenefitFields ==  undefined) return;

        setValue('fbAnnualFinal', fringeBenefitFields.fbAnnual);
        setValue('fbMonthlyFinal', fringeBenefitFields.fbMonthly);
        setValue('fbModelSetupProviderFinal', fringeBenefitFields.fbModelSetupProvider);
        setValue('fbCurrencyFinal', fringeBenefitFields.fbCurrency);

    }, [memoizedWatchedFields])

}
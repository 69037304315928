import React from 'react';
import { Box, List, ListItem } from '@mui/joy';
import { CellCountableMore } from './';

const CellBis = ({ value, limit = 4 }) => {
    if (!Array.isArray(value) && value) {
        value = [value];
    } else if (value == null) {
        value = [];
    }
    const length = Object.keys(value).length;

    const getFirstCharacter = (input) => {
        if (typeof input === 'string' || input instanceof String){
            return input.charAt(0)
        }
        return 'N';
    }

    return (
        <Box className={"list-element-cell cell-bis"}>
            <List className="cell-bis-list">
                {value.map((bi, k) => {
                    if (k >= limit) return;
                    return (
                        <ListItem key={k} className={'cell-bis-list-item'}>
                            <span className="name" title={bi.name}>{getFirstCharacter(bi.name)}</span>
                        </ListItem>
                    );
                })}
                <CellCountableMore limit={limit} length={length} />
            </List>
        </Box>
    );
}

export default CellBis;
import React, { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ListItemButton } from "@mui/joy";
import InputIcon from '@mui/icons-material/Input';
import { AppContext } from "src/mtska-frontend-app-scaffolding";


const Auth0LogoutButton = (props) => {

  const { appConfiguration } = useContext(AppContext)


  const { logout } = useAuth0();

  const handleClick = () => {
    sessionStorage.clear()
    logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_AUTH0_DEFAULT_LOGGEDOUT_URI || appConfiguration.auth0.DEFAULT_LOGGEDOUT_URI
      }
    })
  }

  return (
    <ListItemButton
      {...props}
      onClick={handleClick}
      variant="plain">
      <InputIcon />
      Logout
    </ListItemButton>
  );
};

export default Auth0LogoutButton;

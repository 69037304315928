import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, SectionTitleEmbedded, Tag } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'persons/employment';


const ServicesOfEmployment = (params) => {

    const { t } = useTranslation();

    const { userCapabilities, existingModules } = useContext(AppContext);
   

    // DATA PROVIDER
    const { item, save, loadOne, loadAll, destroy, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);

    const modelName = existingModules[dataProviderName].modelName
    const detailConfig = {
            "tabs": [
                {
                    "name": "services",
                    "fieldsets": [
                        {
                            "name": "services",
                            "fields": {
                                "services": {
                                    "type": "relationship",
                                    "relation": {
                                        "drawerRoute": "services",
                                        "inverseRelation": "employment",
                                        "inverseRelationType": "relationship",
                                        "relationAssociator":"persons/serviceAssociation",
                                        "relationDissociator":"persons/serviceDissociation",
                                        "limit": 1000
                                    },
                                    "view": "service",
                                }
                            }
                        }
                    ]
                }
            ],
            "validation": []
        }


    useEffect(() => {
        loadOne(params.filters?._id);
    }, []);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])

    const handleSave = (data) => {
        save && save({ ...data, filters: params.filters });
    }

    return <>
        {
            item &&
            <>
                <DetailComponent
                    values={{ ...item?.data }}
                    modelName={modelName}
                    detailConfig={detailConfig}
                    onEditing={setEditing}
                    save={handleSave}
                    saving={saving}
                    errors={errors}
                    errorMessage={errorMessage}
                    isEmbedded={true}
                    {...params}
                />
            </>
        }
    </>
}

export default ServicesOfEmployment;
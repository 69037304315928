import React from 'react';
import { Box } from '@mui/joy';
import { useFormatter } from '../../utils';

const CellCurrency = ({ value, type }) => {
    const { formatCurrency } = useFormatter();
    
    return <Box className={"list-element-cell cell-" + type}>{ (value) ? formatCurrency(value) : '-' }</Box>
}

export default CellCurrency
import React from 'react';
import { Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Text } from '../Typography';
import { Icon } from 'src/mtska-frontend-app-component/assets';

const CellUser = ({ value, type = 'simple' }) => {
    const { t } = useTranslation();

    return (
        <Box className={"list-element-cell cell-" + type + " cell-boolean cell-boolean-"+((value) ? "true" : "false")}>
            { value?.auth0_id ? <Text icon="faCircleCheck" level="small">{value.auth0_id.split('|')[0]}</Text> : <Icon icon="faCircleXmark"></Icon>}
        </Box>
    )
}

export default CellUser
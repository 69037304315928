import React, {useContext} from "react";
import { Box } from "@mui/joy";

import { useTranslation } from 'react-i18next'
import Termometer from "./Termometer";
import Tag from "./Tag";
import { Icon } from "../../assets";
import { useFormatter } from "../../utils";
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const VehicleInstalledTermometer = ({ contractStartDate, contractEndDate, contractMonths, contractInitialKM, contractTotalKM, lastTraceKMValue, lastTraceKMDate, layout = "pile" }) => {
    const { t, i18n } = useTranslation();
    const { formatDate, formatNumber, formatPercentage, formatDistance } = useFormatter();
    const { appConfiguration } = useContext(AppContext);

    if (!lastTraceKMValue) lastTraceKMValue = 0;
    if (!contractInitialKM) contractInitialKM = 0;

    const csd = new Date(contractStartDate);
    const ced = new Date(contractEndDate);
    const td = new Date();
    const ltd = new Date(lastTraceKMDate ?? null);

    const contractTotalDuration = Math.floor((ced - csd) / (1000 * 60 * 60 * 24));
    const contractEnlapsed = Math.floor((td - csd) / (1000 * 60 * 60 * 24));
    const contractEnlapsedMonths = Math.floor((td - csd) / (2e3 * 3600 * 365.25));
    const kmTraceFromToday = Math.floor((td - ltd) / (1000 * 60 * 60 * 24));

    const contracEnlapsedOverTotal = contractEnlapsed / contractTotalDuration;
    const kmPercentage = lastTraceKMValue / contractTotalKM;
    const kmToContractEnlapsed = kmPercentage / contracEnlapsedOverTotal;

    return (
        <Box className={"vehicle-termometer vehicle-installed-termometer layout-"+layout}>
            <Box className="trace">
                <Termometer startvalue={formatDistance(contractInitialKM)} endvalue={formatDistance(parseInt(contractTotalKM) + parseInt(contractInitialKM))} percentage={kmPercentage} color={kmTraceFromToday > appConfiguration.custom.traceKmMaxMissingDays ? "danger" : ((kmPercentage < 0.7) ? "secondary" : "warning")} hint={(lastTraceKMValue == 0 ? t('No mileage detection received yet') : formatDistance(lastTraceKMValue) + " (" + formatDate(ltd) +" - "+formatNumber(kmTraceFromToday)+" "+t("days ago")+ ")")} hintposition="top" />
            </Box>
            <Box className="contract">
                <Termometer startvalue={formatDate(csd)} endvalue={formatDate(ced)} percentage={contracEnlapsedOverTotal} color={(contracEnlapsedOverTotal < 0.7) ? "secondary" : "warning"} hint={contractEnlapsedMonths+' '+t('months')+' '+t('out of')+' '+contractMonths} hintposition={layout == "pile" ? "bottom" : "top"} />
            </Box>
            <Box className="analysis">
                {
                    (kmToContractEnlapsed > appConfiguration.custom.mismatchKmTimeMaxPerc || kmToContractEnlapsed < appConfiguration.custom.mismatchKmTimeMinPerc) ? (
                        <>
                            <Icon icon="faArrowTurnLeft" className="upIndicator danger"/>
                            <Tag color="danger" size="small" label={formatPercentage(Math.abs(1 - kmToContractEnlapsed))} icon="faTriangleExclamation" />
                            <Icon icon="faArrowTurnDownLeft" className="downIndicator danger"/>
                        </>
                    ) : (
                        <>
                            <Icon icon="faArrowTurnLeft" className="upIndicator success"/>
                            <Tag color="success" size="small" label={formatPercentage(Math.abs(1 - kmToContractEnlapsed))} icon="faCircleCheck" />
                            <Icon icon="faArrowTurnDownLeft" className="downIndicator success"/>
                        </>
                    )
                }                
            </Box>
        </Box>
    )
}

export default VehicleInstalledTermometer;
export const useStyleVariableInjector = () => {

    const injectColorsToDocumentNode = (colors) => {


        const bodystyle = Object.entries(colors)
            .map(([key, value]) => `${key}:${value};`)
            .join(' ');
            
        document.body.style = bodystyle;

    }


    return {
        injectColorsToDocumentNode
    }
};

import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';
import { useFormatter } from 'src/mtska-frontend-app-component/utils';
import { useTranslation } from 'react-i18next'

const RelationshipFringeBenefitCar = ({ k, v, editing, handleItemDetail, handleItemRemove }) => {
    const { t } = useTranslation();
    const { formatCurrency } = useFormatter();

    const getType = (v) => {
        return v?.type ? v?.type.toLowerCase() : '';
    }

    const getStatus = (v) => {
        return v?.status ? v?.status.toLowerCase() : 'Running';
    }

    return v ? (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content vehicle-type-" + getType(v) + " vehicle-status-" + getStatus(v)}>
                <Box className="url coupled">
                    <Box className="label">{t('Make')}</Box>
                    <Box className="value">{v?.make}</Box>
                </Box>
                <Box className="url coupled">
                    <Box className="label">{t('Model')}</Box>
                    <Box className="value">{v?.model}</Box>
                </Box>
                <Box className="url coupled">
                    <Box className="label">{t('Setup')}</Box>
                    <Box className="value">{v?.setup}</Box>
                </Box>
                <Box className="year coupled">
                    <Box className="label">{t('Year')}</Box>
                    <Box className="value">{v?.fringeBenefitValue?.year}</Box>
                </Box>
                <Box className="value coupled">
                    <Box className="label">25%</Box>
                    <Box className="value">{formatCurrency(v?.fringeBenefitValue?.value25)}</Box>
                </Box>
                <Box className="value coupled">
                    <Box className="label">30%</Box>
                    <Box className="value">{formatCurrency(v?.fringeBenefitValue?.value30)}</Box>
                </Box>
                <Box className="value coupled">
                    <Box className="label">50%</Box>
                    <Box className="value">{formatCurrency(v?.fringeBenefitValue?.value50)}</Box>
                </Box>
                <Box className="value coupled">
                    <Box className="label">60%</Box>
                    <Box className="value">{formatCurrency(v?.fringeBenefitValue?.value60)}</Box>
                </Box>
            </ListItemContent>

            {editing && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    ) : (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content vehicle-type-" + getType(v) + " vehicle-status-" + getStatus(v)}>
                non assegnato
            </ListItemContent>
        </ListItem>
    )
}


export default RelationshipFringeBenefitCar



import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, List, ListItem } from '@mui/joy';
import { RouterAnchor } from "../RouterAnchor"
import { CircularProgress } from "@mui/joy";
import { useTranslation } from 'react-i18next';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { Anchor } from '../Typography';

const AppMenu = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const { userCapabilities, appConfiguration } = useContext(AppContext);

    return (
        <Box className="app-menu">
            {userCapabilities && Object.keys(userCapabilities).length > 0 ?
                <List className="app-menu-portion">
                    {appConfiguration.modules.active.map((module, k) => {
                        if (!module.hasOwnProperty('menuItemScope') || !module.hasOwnProperty('menuItemName')) return;
                        const moduleMenuGroup = appConfiguration.navigation[module.menuItemScope];
                        const moduleMenuItem = moduleMenuGroup[module.menuItemName];
                        let isSelected = false;

                        if (moduleMenuItem.mainMenuVisibility !== true) {
                            return;
                        }

                        if (location.pathname == moduleMenuItem.href) {
                            isSelected = true;
                        } else if (moduleMenuGroup) {
                            for (const key in moduleMenuGroup) {
                                if (location.pathname == moduleMenuGroup[key].href) {
                                    isSelected = true;
                                    break;
                                }
                            }
                        }

                        return (
                            ((moduleMenuItem && userCapabilities) && userCapabilities[moduleMenuItem.capability]) && (
                                <ListItem key={k} className={(isSelected) ? 'app-menu-item app-menu-item-current' : 'app-menu-item'}>
                                    <RouterAnchor icon={moduleMenuItem.icon} href={moduleMenuItem.href} underline="none">
                                        <span className="name">{t(moduleMenuItem.scope)}</span>
                                    </RouterAnchor>
                                </ListItem>
                            )
                        );
                    })}
                    {
                        appConfiguration?.custom?.menu?.length > 0 && appConfiguration.custom.menu.map((menu, k) => (
                            <ListItem className={'app-menu-item app-menu-item-custom'} key={k}>
                                <Anchor icon={menu.icon} href={menu.href} target="_blank" underline="none">
                                    <span className="name">{t(menu.label)}</span>
                                </Anchor>
                            </ListItem>
                        ))
                    }

                </List> :
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                </div>
            }
        </Box>
    );
}

export default AppMenu;